<script setup>
import { useProductOutcomesV3Loader } from '@/api';
import { useI18n } from '@/util';
import GettingStartedIllustration from '../assets/getting-started.png';
import OnboardingWizardCommonStep from '../common/OnboardingWizardCommonStep.vue';
import { STEP_GETTING_STARTED_CHECKLIST } from '../constants';
import { useOnboardingWizardTracking } from '../useOnboardingWizardTracking';

const props = defineProps({
  nextButtonText: {
    type: String,
    required: true,
  },
  state: {
    type: Object,
    required: true,
  },
});

/**
 * Triggers sending step result data back to main state and move to next step
 *
 * @param {boolean} hasChanged this step has changed data compared to the history. compare with `stateData` for the step
 * @param {object} stepData step result data
 * @param {object} [stateData] full state data - optional if you edited anything in the full state
 */
const emit = defineEmits(['nextStep']);

/** use stateData to check if there's old values for this state and if they have changed */
const { [STEP_GETTING_STARTED_CHECKLIST]: stateData } = props.state;

const { t } = useI18n();
const { trackGettingStartedStepContinueWithZeroSelection } = useOnboardingWizardTracking();

const outcomesState = useProductOutcomesV3Loader({ count: Infinity });
const { items: outcomes } = outcomesState;

const selectedItems = shallowRef(stateData?.selectedItems ?? []);
const showSelectionWarning = shallowRef(stateData?.alertShown ?? false);
const alertShown = shallowRef(false);

function hasChanged() {
  return !stateData || stateData?.selectedItems?.filter((x) => !selectedItems.value.includes(x))?.length !== 0;
}

function nextStep() {
  const productOutcomesSelected = selectedItems.value.map((itemId) => {
    const matchedProduct = outcomes.value.find((product) => product.id === itemId);
    return matchedProduct ? matchedProduct.description : null;
  });
  if (selectedItems.value.length === 0 && !showSelectionWarning.value) {
    trackGettingStartedStepContinueWithZeroSelection();
    showSelectionWarning.value = true;
    alertShown.value = true;
    return;
  }

  emit('nextStep', hasChanged(), {
    selectedItems: selectedItems.value,
    alertShown: alertShown.value,
    outcomesSelected: productOutcomesSelected.length === 0 ? 'none' : productOutcomesSelected.join(' | '),
    outcomesSelectedCount: selectedItems.value.length,
  });
}
</script>
<template>
  <OnboardingWizardCommonStep
    :title="t('Help us personalize your experience...')"
    :description="t('What do you want to do with {teamwork}? Select all that apply', { teamwork: 'Teamwork.com' })"
  >
    <WidgetLoadingState :state="outcomesState" :errorMessage="t('Unable to load options')">
      <div v-for="outcome in outcomes" :key="outcome.id" class="mb-1 flex-col">
        <LscCheckbox
          v-model="selectedItems"
          :value="outcome.id"
          :label="outcome.description"
          @click="showSelectionWarning = false"
        />
      </div>

      <div v-if="showSelectionWarning && selectedItems.length == 0" class="pt-4 text-critical">
        {{
          t(
            'Telling us how you plan to work with {teamwork} will help us to provide you with a better trial experience. Are you sure you want to continue without making a selection?',
            { teamwork: 'Teamwork.com' },
          )
        }}
      </div>

      <slot name="underFieldsButtons" :nextButtonText="nextButtonText" :nextStep="nextStep" />
    </WidgetLoadingState>

    <template #right>
      <div class="flex h-full flex-row items-center">
        <img :src="GettingStartedIllustration" alt="" class="max-h-96 w-full" />
      </div>
    </template>
  </OnboardingWizardCommonStep>
</template>
