<script setup>
import useVuelidate from '@vuelidate/core';
import { useRouter } from 'vue-router';
import { useI18n, useValidators } from '@/util';
import { FeedbackButton } from '@/module/feedback';
import { useReportTracking } from '../../useReportTracking';
import ReportCustomBuilderOptions from './options/ReportCustomBuilderOptions.vue';
import ReportCustomBuilderPreview from './preview/ReportCustomBuilderPreview.vue';
import { provideReportCustomBuilder } from './useReportCustomBuilder.js';
import { useReportCustomBuilderSettings } from './useReportCustomBuilderSettings.js';

const props = defineProps({
  report: {
    type: Object,
    default: () => ({}),
  },
  copyingReport: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['updated', 'close', 'handleCustomReportTrack']);

const { t } = useI18n();
const toast = useLsToast();
const router = useRouter();
const { trackReportsEvent } = useReportTracking();
const { saveReport, hasColumns, currentReport, validateReportParams, isCustomReportBuilderCustomised } =
  provideReportCustomBuilder(toRef(props, 'report'));
const { helpers, required, maxLength } = useValidators();
const { advancedReport } = useReportCustomBuilderSettings();

currentReport.value.name = props.copyingReport ? `${props.report.name} duplicate` : props.report.name;

const duplicateNameError = shallowRef(false);
const toggleConfirmDialog = shallowRef(false);

const rules = {
  currentReport: {
    name: {
      required: helpers.withMessage(t('Report name is required'), required),
      unique: helpers.withMessage(t('Report name already exists'), () => !duplicateNameError.value),
      maxLength: helpers.withMessage(t('Report name must have less than 255 characters'), maxLength(255)),
    },
  },
  hasColumns: {
    isTrue: helpers.withMessage(t('At least one column is required'), (value) => value === true),
  },
};

const v$ = useVuelidate(rules, { currentReport, hasColumns }, { $autoDirty: true, $lazy: false });

const isEditing = computed(() => Boolean(props.report.id) && !props.copyingReport);
const isSaving = shallowRef(false);

const actionText = computed(() => {
  return isEditing.value ? t('Update report') : t('Save report');
});

async function saveOrEditReport() {
  duplicateNameError.value = false;
  if (!isEditing.value) {
    handleCustomReportTrack(
      'custom_report_modal',
      'save_custom_report_clicked',
      'advanced',
      props.copyingReport ? 'copy' : 'create',
    );
  }

  v$.value.$touch();

  if (v$.value.$invalid) {
    validateReportParams(props.copyingReport);
    return;
  }

  if (isSaving.value) {
    return;
  }

  isSaving.value = true;

  try {
    const response = await saveReport(props.copyingReport);

    if (response === false) {
      isSaving.value = false;
      return;
    }

    if (isEditing.value) {
      toast.success(t('Regenerating custom report'));
      emit('updated');
    }

    trackReportsEvent('reports', 'custom_report_created', 'activation', 'custom_reports');
    isSaving.value = false;
    emit('close');
    router.push({ path: `/reports/${response.data.customReport.id}/`, replace: true });
  } catch (error) {
    if (error.status === 409) {
      duplicateNameError.value = true;
      v$.value.$touch();
    }
    isSaving.value = false;
  }
}

function handleClose() {
  if (isCustomReportBuilderCustomised.value) {
    toggleConfirmDialog.value = true;
    handleCustomReportTrack('custom_reports', 'discard_report_modal_viewed', 'advanced');
  } else {
    emit('close');
  }
}

function discardChanges() {
  toggleConfirmDialog.value = false;
  handleCustomReportTrack('discard_report_modal', 'discard_clicked', 'advanced');
  emit('close');
}

function handleCustomReportTrack(pageType, eventAction, eventCategory, ...args) {
  emit('handleCustomReportTrack', pageType, eventAction, eventCategory, ...args);
}
</script>

<template>
  <LscDialogCard size="full" :title="t('Custom report builder')" @close="handleClose">
    <template #prependHeaderRight>
      <FeedbackButton
        :data-identifier="`${advancedReport ? 'feedback-advanced' : 'feedback'}-custom-reports-builder`"
      />
    </template>
    <template #content>
      <section class="flex h-full w-full items-stretch overflow-hidden text-body-1">
        <ReportCustomBuilderOptions
          class="border-r border-t border-separator"
          :validation="v$"
          @handleCustomReportTrack="handleCustomReportTrack"
        />
        <div class="flex w-full flex-1 flex-col overflow-hidden border-t border-separator bg-surface-default">
          <ReportCustomBuilderPreview />

          <div class="flex shrink-0 self-end pb-6 pr-6">
            <LscButton :loading="isSaving" variant="primary" size="lg" @click="saveOrEditReport">
              {{ actionText }}
            </LscButton>
          </div>
        </div>
      </section>
    </template>
  </LscDialogCard>
  <LscDialogConfirm
    v-model="toggleConfirmDialog"
    :title="t('You have changes')"
    :message="t('Are you sure you want to discard these changes?')"
    :confirmButtonText="t('Discard changes')"
    :cancelButtonText="t('Continue editing')"
    @confirm="discardChanges"
    @cancel="handleCustomReportTrack('discard_report_modal', 'cancel_clicked', 'advanced')"
  />
</template>
