import { Cancel, isCancel } from 'axios';
import { deepEqual, exponentialBackOff, useBackOff } from '@/util';
import { useAxios } from './useAxios';
import { useLoaders } from './useLoaders';
import { useLoadersInSyncSource } from './useLoadersInSync';
import { useRealTimeUpdates } from './useRealTimeUpdates';

/**
 * Loads a single item from the Teamwork API.
 * Supports retrying with customizable delay and real-time updates.
 * @template Item
 * @template Meta
 * @param {Object} options
 * @param {MaybeRef<string | null | undefined>} options.url The request url. If it is not a string, then nothing will be loaded.
 * @param {MaybeRef<object>} [options.params={}] The request params. If it is not an object, nothing will be loaded.
 * @param {(response: unknown) => Item} options.responseToItem Gets an item from the server response.
 * @param {(response: unknown) => Meta} [options.responseToMeta] Gets metadata from the server response.
 * @param {string} [options.type] The type name of the loaded item.
 * @param {(retryAttempt: unknown) => number} [options.retryDelay] The retry delay strategy.
 * @param {MaybeRef<boolean>} [options.cache] Should the first request be sent directly to the browser cache.
 */
export function useItemLoader({
  url: _url,
  params: _params = {},
  responseToItem: _responseToItem = () => undefined,
  responseToMeta: _responseToMeta = () => null,
  type: _type = undefined,
  retryDelay = exponentialBackOff({ minDelay: 4000 }),
  cache: _cache = true,
}) {
  const axios = useAxios();
  const { registerItems } = useLoaders();
  const { handlingEventFromSocket } = useRealTimeUpdates();
  const url = shallowRef(_url);
  const params = shallowRef(_params);
  const responseToItem = shallowRef(_responseToItem);
  const responseToMeta = shallowRef(_responseToMeta);
  const type = shallowRef(_type);
  const backOff = useBackOff({ retryDelay });
  const cache = shallowRef(_cache);
  /** @type {ShallowRef<undefined | null | Item>} */
  const loadedItem = shallowRef(undefined);
  const needsRefresh = shallowRef(true);
  /** The error produced by the last axios request. @type {ShallowRef<import("axios").AxiosError|undefined>} */
  const error = shallowRef(undefined);
  /** The loaded metadata. @type {ShallowRef<undefined | Meta>}  */
  const meta = shallowRef(undefined);
  const cancel = shallowRef(undefined);

  /**
   * Indicates if the loader has completed its initial load.
   * @type {ShallowRef<boolean>}
   */
  const loaded = shallowRef(false);
  const optimisticUpdates = shallowRef(new Set());

  const urlAndParamsValid = computed(() =>
    Boolean(url.value && typeof url.value === 'string' && params.value && typeof params.value === 'object'),
  );

  /**
   * The loaded item, if loaded and exists, `null`, if loaded and does not exist, or `undefined`, if not loaded yet.
   * @type {ComputedRef<undefined | null | Item>}
   */
  const item = computed(() => {
    let processedItem = loadedItem.value;
    optimisticUpdates.value.forEach((optimisticUpdate) => {
      processedItem = optimisticUpdate.apply(processedItem);
    });
    return processedItem;
  });

  /**
   * Indicates if the item is in sync with the server.
   * @type {ComputedRef<boolean>}
   */
  const inSync = computed(() => {
    if (!urlAndParamsValid.value) {
      // invalid url or params
      return true;
    }
    return (
      // no real-time updates
      !needsRefresh.value &&
      // no optimistic updates
      optimisticUpdates.value.size === 0
    );
  });

  useLoadersInSyncSource(inSync);

  let isInitialState = true;

  /** @type {'user'|'event/ws'|'event/local'} */
  let triggeredBy = 'user';

  /** Used to prevent retries on 400 client errors. */
  let blockRequest = false;

  /**
   * Forces the loader to refresh the data from the server.
   * @type {() => void}
   */
  function refresh() {
    needsRefresh.value = true;
    blockRequest = false;
    if (cancel.value) {
      cancel.value();
    }
    backOff.reset();
    optimisticUpdates.value.forEach((optimisticUpdate) => {
      if (optimisticUpdate.updated) {
        clearTimeout(optimisticUpdate.timeout);
        // eslint-disable-next-line no-param-reassign
        optimisticUpdate.refreshing = true;
      }
    });
    triggeredBy = handlingEventFromSocket.value ? 'event/ws' : 'event/local';
  }

  function reset() {
    isInitialState = true;
    loadedItem.value = undefined;
    error.value = undefined;
    meta.value = undefined;
    loaded.value = false;
    refresh();
    triggeredBy = 'user';
  }

  /**
   * Resets the back-off and retries the request.
   * @type {() => void}
   */
  function retry() {
    backOff.reset();
  }

  function resetOnChange(newValue, oldValue) {
    if (deepEqual(newValue, oldValue)) {
      return;
    }
    reset();
  }

  /**
   * Updates the item locally, while waiting for the same change to be saved on the server.
   * @param apply {(item: Item | null | undefined) => Item | null | undefined}
   *   Gets an item and returns its new version with modifications.
   *   It MUST NOT modify the original item.
   * @param promise {Promise} A Promise tracking the request which makes the corresponding change on the server.
   */
  function update(apply, promise) {
    const optimisticUpdate = { apply, promise };
    promise.then(
      () => {
        if (needsRefresh.value) {
          // Keep the update until the data is refreshed.
          optimisticUpdate.promise = undefined;
        } else {
          // Discard the update, as it did not affect this loader.
          optimisticUpdates.value.delete(optimisticUpdate);
          triggerRef(optimisticUpdates);
        }
      },
      () => {
        // Discard the update, as it failed.
        optimisticUpdates.value.delete(optimisticUpdate);
        triggerRef(optimisticUpdates);
      },
    );
    // Apply the update optimistically.
    optimisticUpdates.value.add(optimisticUpdate);
    triggerRef(optimisticUpdates);
  }

  // Prunes optimistic updates which have been saved and read back from the server.
  function prune() {
    if (!needsRefresh.value) {
      optimisticUpdates.value.forEach((optimisticUpdate) => {
        if (!optimisticUpdate.promise) {
          optimisticUpdates.value.delete(optimisticUpdate);
          triggerRef(optimisticUpdates);
        }
      });
    }
  }

  /**
   * The initial load.
   * @returns {Promise<void>}
   */
  async function load() {
    if (blockRequest) {
      return; // got a 4xx client error on the previous request
    }
    if (cancel.value) {
      return; // loading in progress
    }
    if (backOff.active.value) {
      return; // waiting for the next retry
    }
    if (!needsRefresh.value) {
      return; // already in sync with the server
    }
    if (!urlAndParamsValid.value) {
      needsRefresh.value = false;
      return; // invalid url or params
    }

    // We force using the browser cache for the initial request in order to show the cached data immediately.
    // After that request completes, we immediately call `refresh` to load fresh data from the server.
    // Note also that direct requests to the browser cache are possible only for same-origin requests.
    // See https://developer.mozilla.org/en-US/docs/Web/API/Request/cache
    const shouldUseCache =
      isInitialState && cache.value && (/^\/\w/.test(url.value) || import.meta.env.MODE === 'test');

    try {
      const response = await new Promise((resolve, reject) => {
        // Marks the request as canceled but allows it to complete,
        // so that it could be cached by the browser.
        cancel.value = () => reject(new Cancel());
        axios
          .get(url.value, {
            params: params.value,
            headers: {
              'Triggered-By': triggeredBy,
              'Retry-Attempt': backOff.retryAttempt.value + 1,
            },
            fetchOptions: {
              mode: shouldUseCache ? 'same-origin' : undefined,
              cache: shouldUseCache ? 'only-if-cached' : undefined,
            },
          })
          .then(resolve, reject);
      });

      error.value = undefined;

      loadedItem.value = responseToItem.value(response);
      meta.value = responseToMeta.value(response);
      needsRefresh.value = false;

      loaded.value = true;
      backOff.reset();
    } catch (axiosError) {
      if (shouldUseCache || isCancel(axiosError)) {
        return;
      }

      if (axiosError.response?.status === 404) {
        error.value = undefined;
        loadedItem.value = null;
        meta.value = null;
        needsRefresh.value = false;
        loaded.value = true;
        backOff.reset();
      } else {
        error.value = axiosError;
        // Any 400 client errors should not be retried.
        if (axiosError.response?.status >= 400 && axiosError.response?.status < 500) {
          needsRefresh.value = false;
          blockRequest = true;
          backOff.reset();
        } else {
          backOff.start();
        }

        if (import.meta.env.MODE !== 'test') {
          // eslint-disable-next-line no-console
          console.error('Error in useItemLoader:', axiosError);
        }
      }
    } finally {
      isInitialState = false;
      cancel.value = undefined;
      if (shouldUseCache) {
        refresh();
        triggeredBy = 'user';
      }
    }
  }

  watch(url, resetOnChange);
  watch(params, resetOnChange);
  watch(responseToItem, resetOnChange);
  watch(responseToMeta, resetOnChange);

  watch(cancel, load);
  watch(backOff.active, load);
  watch(needsRefresh, load);
  watch(needsRefresh, prune);

  onScopeDispose(reset);
  load();
  registerItems(
    type,
    computed(() => (item.value != null ? [item.value] : [])),
  );

  return {
    state: { item, inSync, loaded, meta, error, retry },
    refresh,
    update,
  };
}
