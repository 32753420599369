<script setup>
// This isnt automatically imported by Vite, so we need to import it manually
import { VDialog } from 'vuetify/components';
import { provideKeyboardShortcut } from '@/util';

defineProps({
  /**
   * Controls focus retention on dialog's first child.
   * Set to false for compatibility with external tools like Pendo.
   * @see https://vuetifyjs.com/en/api/v-dialog/#props-retain-focus
   */
  retainFocus: {
    type: Boolean,
    default: true,
  },
});

const modelValue = defineModel({
  type: Boolean,
  default: false,
});

const { shouldCloseOnClickOutside } = provideLsDialog();
provideKeyboardShortcut(modelValue);

function close() {
  modelValue.value = false;
}

let clickOutsideEvent = null;

function handleClickOutside(event) {
  clickOutsideEvent = event;
  nextTick(() => {
    clickOutsideEvent = null;
  });
}

const modelValueFiltered = computed({
  get() {
    return modelValue.value;
  },
  set(value) {
    /**
     * Do not close the dialog:
     * - when a toast was clicked.
     * - when pendo guide is clicked.
     * - when shouldCloseOnClickOutside is false.
     */
    if (clickOutsideEvent?.target.closest('.LscToast, #pendo-guide-container') || !shouldCloseOnClickOutside.value) {
      return;
    }
    modelValue.value = value;
  },
});
</script>

<template>
  <!-- eslint-disable-next-line lightspeed/no-restricted-component -->
  <VDialog
    v-model="modelValueFiltered"
    class="LscDialog"
    :scrollable="true"
    :retainFocus="retainFocus"
    @click:outside="handleClickOutside"
  >
    <template #activator="activator">
      <slot name="activator" v-bind="activator" />
    </template>
    <slot name="default" :close="close" />
  </VDialog>
</template>

<style>
.LscDialog.v-dialog > .v-overlay__content {
  @apply pointer-events-none w-full items-center;
}

.LscDialog.v-dialog.alignLeft > .v-overlay__content {
  @apply items-start;
}
</style>
