<script setup>
import { useTimeoutFn } from '@vueuse/core';
import {
  useCurrentAccountState,
  useCurrentUserState,
  useExperimentR2401,
  useFeatures,
  useHubspotTokenV3Loader,
  usePendo,
} from '@/api';
import { useQuickView, useRoute } from '@/route';
import { escapeRegex, useI18n, useNowByMinute } from '@/util';
import { useOnboardingChecklist } from '@/module/onboarding';

const { item: account, inSync: accountInSync } = useCurrentAccountState();
const { item: user, inSync: userInSync } = useCurrentUserState();
const { item: token, inSync: tokenInSync } = useHubspotTokenV3Loader();
const { hubspotChatEnabled, initialized } = useFeatures();
const { isChecklistFlowEnabled } = useOnboardingChecklist();
const { isExpR2401Variation } = useExperimentR2401();
const { trackPendoEvent } = usePendo();
const { t } = useI18n();
const now = useNowByMinute();
const route = useRoute();

const commonMetrics = ['plan_name', 'page', 'user_role', 'days_since_purchase'];

const isChatIconDismissed = shallowRef(false);
const shouldShowDismissButton = shallowRef(false);
const isChatOpen = shallowRef(false);
const shouldTrackChatDisplayed = shallowRef(true);

const startedFiveMonthsAgo = computed(() => now.value.diff(account.value.dateSignedUp).as('months') < 5);
const isSubscriptionRoute = computed(() => route.path.includes('/settings/subscription'));

const { isQuickViewOpen } = useQuickView();

const shouldShowChatIcon = computed(() =>
  Boolean(
    !isChecklistFlowEnabled.value && !isExpR2401Variation.value && !isChatIconDismissed.value && !isQuickViewOpen.value,
  ),
);

function readCookie(name) {
  const match = document.cookie.match(new RegExp(`(^|;\\s*)(${escapeRegex(name)})=([^;]*)`));
  return match ? decodeURIComponent(match[3]) : null;
}

function displayChatIcon() {
  window.HubSpotConversations.widget.load();
  // To avoid tracking the event multiple times
  if (shouldTrackChatDisplayed.value) {
    trackPendoEvent({
      eventName: 'HUBSPOT_CHAT_EVENT',
      commonMetrics,
      metadata: {
        event_action: 'chat_icon_displayed',
      },
    });
    shouldTrackChatDisplayed.value = false;
  }
}

function removeChatIcon() {
  window.HubSpotConversations.widget.remove();
}

function dismissChatIcon() {
  isChatIconDismissed.value = true;
  shouldShowDismissButton.value = false;
  removeChatIcon();
  trackPendoEvent({
    eventName: 'HUBSPOT_CHAT_EVENT',
    commonMetrics,
    metadata: {
      event_action: 'chat_icon_dismissed',
    },
  });
}

function addEventListeners() {
  const container = document.getElementById('hubspot-messages-iframe-container');

  const chatCheckInterval = setInterval(() => {
    isChatOpen.value = Boolean(readCookie('hs-messages-is-open') === 'true');
    if (isChatIconDismissed.value) {
      clearInterval(chatCheckInterval);
    }
  }, 200);

  const { start: startDismissibleTimer, stop: stopDismissibleTimer } = useTimeoutFn(() => {
    shouldShowDismissButton.value = false;
  }, 3500);

  container.addEventListener('mouseenter', () => {
    stopDismissibleTimer();
    shouldShowDismissButton.value = !isChatOpen.value;
  });

  container.addEventListener('mouseout', () => {
    startDismissibleTimer();
  });
}

function onConversationsAPIReady() {
  window.HubSpotConversations.on('widgetLoaded', () => {
    addEventListeners();
  });

  if (shouldShowChatIcon.value) {
    displayChatIcon();
  }
}

function loadHubspotScript() {
  const hubspotSrcUrl =
    import.meta.env.DEV || account.value.isStaging
      ? '//js-eu1.hs-scripts.com/26176897.js'
      : '//js-eu1.hs-scripts.com/26079973.js';
  const hubspotScript = document.createElement('script');
  hubspotScript.id = 'hs-script-loader';
  hubspotScript.async = true;
  hubspotScript.defer = true;
  hubspotScript.src = hubspotSrcUrl;
  document.body.appendChild(hubspotScript);

  window.hsConversationsSettings = {
    loadImmediately: false,
    identificationEmail: user.value.emailAddress,
    identificationToken: token.value,
  };

  if (window.HubSpotConversations) {
    onConversationsAPIReady();
  } else {
    window.hsConversationsOnReady = [onConversationsAPIReady];
  }
}

onBeforeUnmount(() => {
  removeChatIcon();
});

const unwatch = watch(
  () =>
    Boolean(
      userInSync.value &&
        accountInSync.value &&
        initialized.value &&
        tokenInSync.value &&
        hubspotChatEnabled.value &&
        token.value &&
        !user.value.impersonating &&
        !user.value.twAdminId &&
        ((startedFiveMonthsAgo.value && account.value.isPaid) || isSubscriptionRoute.value),
    ),
  (shouldLoadHubspot) => {
    if (shouldLoadHubspot) {
      loadHubspotScript();
      queueMicrotask(() => unwatch());
    }
  },
  { immediate: true },
);

watch(shouldShowChatIcon, (shouldShow) => {
  if (window.HubSpotConversations?.widget) {
    if (shouldShow) {
      displayChatIcon();
    } else {
      removeChatIcon();
    }
  }
});

watch(isChatOpen, (isOpen) => {
  shouldShowDismissButton.value = !isOpen;
  trackPendoEvent({
    eventName: 'HUBSPOT_CHAT_EVENT',
    commonMetrics,
    metadata: {
      event_action: isOpen ? 'chat_opened' : 'chat_closed',
    },
  });
});
watch(route, () => {
  if (window.HubSpotConversations?.widget && shouldShowChatIcon.value) {
    window.HubSpotConversations.widget.refresh();
  }
});
</script>

<template>
  <div
    v-if="shouldShowChatIcon && shouldShowDismissButton"
    type="button"
    class="fixed bottom-18 right-2 z-[--hubspot-dismiss-button-z-index]"
  >
    <LscIconButton
      v-LsdTooltip="t('Dismiss chat')"
      :ariaLabel="t('Dismiss chat')"
      icon="lsi-close"
      variant="secondary"
      class="flex items-center justify-center rounded-full"
      size="xs"
      @click.stop="dismissChatIcon"
    />
  </div>
</template>

<style>
#hubspot-messages-iframe-container {
  z-index: 2399 !important; /* Don't show over quick views*/
}
</style>
