<script setup>
import useVuelidate from '@vuelidate/core';
import { useCurrentAccount } from '@/api';
import { useI18n, useValidators } from '@/util';
import { useWorkflowStageAvailableColor } from '@/module/workflow';
import OnboardingWizardCommonStep from '../common/OnboardingWizardCommonStep.vue';
import OnboardingWizardCommonPreview from '../common/preview/OnboardingWizardCommonPreview.vue';
import {
  PREVIEW_TAB_BOARD,
  PREVIEW_TAB_LIST,
  PREVIEW_TAB_TABLE,
  SAMPLE_USER_TASKS_DATA,
  STEP_BOARD_COLUMNS,
  STEP_SELECT_VIEW,
} from '../constants';

const props = defineProps({
  nextButtonText: {
    type: String,
    required: true,
  },
  state: {
    type: Object,
    required: true,
  },
});

/**
 * Triggers sending step result data back to main state and move to next step
 *
 * @param {boolean} hasChanged this step has changed data compared to the history. compare with `stateData` for the step
 * @param {object} stepData step result data
 * @param {object} [stateData] full state data - optional if you edited anything in the full state
 */
const emit = defineEmits(['nextStep']);

/** use stateData to check if there's old values for this state and if they have changed */
const { [STEP_BOARD_COLUMNS]: stateData, [STEP_SELECT_VIEW]: selectViewState } = props.state;

const { t } = useI18n();
const { required, helpers } = useValidators();
const account = useCurrentAccount();

const projectName = selectViewState?.name ?? '';
const tasksData = selectViewState?.tasksList ?? [];

const workflowName = shallowRef(stateData?.workflowName ?? `${account.value.companyName} Workflow`);
const savedOrPrefilledStageCopy = stateData?.columns?.length > 0 ? stateData.columns : SAMPLE_USER_TASKS_DATA.stages;
// eslint-disable-next-line lightspeed/prefer-shallow-ref
const stages = ref([...savedOrPrefilledStageCopy]);

const rules = computed(() => {
  return {
    workflowName: {
      required: helpers.withMessage(t('Workflow name is required'), required),
    },
    stages: {
      $each: helpers.forEach({
        name: {
          required: helpers.withMessage(t('Stage name is required'), required),
        },
      }),
    },
  };
});

const v$ = useVuelidate(rules, { workflowName, stages }, { $autoDirty: false });

const errorMessages = computed(() => {
  const errorsByField = {};
  v$.value.$errors.forEach((val) => {
    errorsByField[val.$property] = val.$message;
  });
  return errorsByField;
});

const previewData = computed(() => ({
  ...tasksData,
  boardColumns: stages.value,
}));

const { nextAvailableColor } = useWorkflowStageAvailableColor({
  usedColors: computed(() => stages.value.map(({ color }) => color)),
});

const workflowInputRef = shallowRef(null);
const stageInputRef = shallowRef([]);
function nextStep() {
  v$.value.$touch();
  if (v$.value.$error) {
    if (errorMessages.value.workflowName) {
      workflowInputRef.value.focus();
    } else {
      const firstErrorInputIndex = errorMessages.value.stages.findIndex((error) => error.length > 0);
      const el = stageInputRef.value[firstErrorInputIndex]?.$el;
      if (el) {
        el.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    }
    return;
  }

  const numberOfColumnsIntroduced = stages.value.filter((stage) => stage !== '').length;
  const hasChanged =
    stages.value.toString() !== stateData?.columns.toString() || workflowName.value !== stateData?.workflowName;
  emit('nextStep', hasChanged, {
    columns: stages.value,
    columnsPreviewData: previewData.value,
    numberOfColumnsIntroduced,
    workflowName: workflowName.value,
  });
}

function addStage() {
  const newStageId = stages.value.length + 1;
  stages.value.push({
    id: newStageId,
    name: '',
    color: nextAvailableColor.value,
  });
  // Show a dummy task card in the preview column
  const previewTaskCard = {
    id: `${newStageId}1`,
    identifier: `${newStageId}1`,
    columnId: newStageId,
  };
  tasksData.tasklists.push({
    id: `${newStageId}1`,
    tasks: [previewTaskCard],
  });
}

function removeStageAtIndex(index) {
  stages.value.splice(index, 1);
}

const oldIndex = shallowRef(-1);
const draggingOverIndex = shallowRef(-1);
const dragPosition = shallowRef('');

function handleStageDrag(e, index) {
  oldIndex.value = index;
  e.dataTransfer.effectAllowed = 'move';
}

function handleStageDragover(e, index) {
  e.preventDefault(); // To prevent cursor-drag-not-allowed on windows
  draggingOverIndex.value = index;

  const { top, height } = e.currentTarget.getBoundingClientRect();
  // above = dragging over top 50%
  dragPosition.value = e.clientY < top + height / 2 ? 'above' : 'below';
}

function handleStageDrop() {
  const stage = stages.value[oldIndex.value];
  const newIndex = dragPosition.value === 'above' ? draggingOverIndex.value - 1 : draggingOverIndex.value;
  // Not moved to the same position
  if (oldIndex.value !== newIndex) {
    stages.value.splice(oldIndex.value, 1);
    stages.value.splice(draggingOverIndex.value, 0, stage);
  }
}

function handleStageDragEnd() {
  oldIndex.value = -1;
  draggingOverIndex.value = -1;
  dragPosition.value = '';
}
</script>

<template>
  <OnboardingWizardCommonStep :title="t('Create your workflow')">
    <p class="mb-2 mt-2 text-body-1 font-medium">{{ t('Workflow name') }}</p>
    <VTextField
      ref="workflowInputRef"
      v-model="workflowName"
      :placeholder="t('Enter a workflow name')"
      :errorMessages="errorMessages?.workflowName"
      :maxLength="255"
      :autofocus="true"
      autocomplete="off"
      @blur="workflowName = workflowName.trim()"
    />

    <p class="mb-2 mt-5 text-body-1 font-medium">{{ t('Stages') }}</p>
    <div class="flex max-h-64 w-full flex-col gap-2 overflow-auto" @dragover.prevent @drop="handleStageDrop">
      <template v-for="(stage, index) in stages" :key="`stage-${index}`">
        <div
          class="h-10 w-full rounded-md border border-dashed border-bold bg-selected"
          :class="{ hidden: draggingOverIndex !== index || dragPosition !== 'above' }"
        />

        <LscInputBlock
          ref="stageInputRef"
          :draggable="true"
          class="bg-default"
          :class="{
            hidden: index === oldIndex,
            '!border-critical-default': errorMessages?.stages?.[index]?.[0],
          }"
          @drag="handleStageDrag($event, index)"
          @dragover="handleStageDragover($event, index)"
          @dragend="handleStageDragEnd"
        >
          <template #dragHandle>
            <LscIcon icon="lsi-drag" />
          </template>

          <template #prepend>
            <LscColorPickerMenu v-model="stage.color" required>
              <template #activator="activator">
                <LscColorPickerButton v-bind="activator.props" :color="stage.color" :ariaHasPopup="true" size="sm" />
              </template>
            </LscColorPickerMenu>
          </template>

          <LscInputBlockInput v-model="stage.name" :placeholder="t('Enter a stage name')" maxlength="255" />

          <template #append>
            <LscIconButton
              v-LsdTooltip="t('Delete stage')"
              class="opacity-0 hover:text-icon-critical focus-visible:opacity-100 group-hover/LscInputBlock:opacity-100"
              icon="lsi-delete"
              :ariaLabel="t('Delete stage')"
              size="sm"
              variant="plain-secondary"
              :disabled="stages.length === 1"
              @click="removeStageAtIndex(index)"
            />
          </template>
        </LscInputBlock>
        <div v-if="errorMessages?.stages?.[index]?.length" class="flex items-center gap-1 text-body-2 text-critical">
          <LscIcon icon="lsi-customfield-status" size="xs" />
          {{ errorMessages.stages[index][0] }}
        </div>

        <div
          class="h-10 w-full rounded-md border border-dashed border-bold bg-selected"
          :class="{ hidden: draggingOverIndex !== index || dragPosition !== 'below' }"
        />
      </template>
    </div>
    <LscButton variant="tertiary" prependIcon="lsi-add" size="md" class="mx-2 mt-3" @click="addStage">
      {{ t('Add stage') }}
    </LscButton>

    <slot name="underFieldsButtons" :nextButtonText="nextButtonText" :nextStep="nextStep" />

    <template #right>
      <OnboardingWizardCommonPreview
        :projectName="projectName"
        :preselectedTab="PREVIEW_TAB_BOARD"
        :tasksData="previewData"
        :tabs="[PREVIEW_TAB_TABLE, PREVIEW_TAB_BOARD, PREVIEW_TAB_LIST]"
        :tabsClickable="false"
      />
    </template>

    <LscAlert class="mt-8" varinat="info">
      <span>{{ t('This will be the default workflow for all new projects but can be changed later.') }}</span>
    </LscAlert>
  </OnboardingWizardCommonStep>
</template>
