import { useItemLoader } from '../base/useItemLoader.js';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

function responseToItem(response) {
  return response.data;
}

export function useIntegrationParagonAuthV1Loader({ active: _active = true } = {}) {
  const active = shallowRef(_active);

  const { state, refresh } = useItemLoader({
    url: computed(() => active.value && '/synthesis/api/v2/paragon/auth.json'),
    responseToItem,
  });

  useRealTimeUpdates((event) => {
    if (event.type === 'integration' && ['paragon-enabled', 'paragon-disabled'].includes(event.action)) {
      refresh();
    }
  });

  return state;
}
