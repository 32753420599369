<script setup>
import useVuelidate from '@vuelidate/core';
import { useClientsV3Loader, useCurrentAccount } from '@/api';
import { capitalizeFirstLetter, useI18n, useValidators } from '@/util';
import OnboardingWizardCommonStep from '../common/OnboardingWizardCommonStep.vue';
import OnboardingWizardCommonPreview from '../common/preview/OnboardingWizardCommonPreview.vue';
import { PREVIEW_TAB_BOARD, PREVIEW_TAB_LIST, PREVIEW_TAB_TABLE, STEP_ADD_CLIENT_GOAL } from '../constants';
import { useOnboardingWizardTracking } from '../useOnboardingWizardTracking';

const props = defineProps({
  nextButtonText: {
    type: String,
    required: true,
  },
  state: {
    type: Object,
    required: true,
  },
  showSkipButton: {
    type: Boolean,
    default: false,
  },
});

/**
 * Triggers sending step result data back to main state and move to next step
 *
 * @param {boolean} hasChanged this step has changed data compared to the history. compare with `stateData` for the step
 * @param {object} stepData step result data
 * @param {object} [stateData] full state data - optional if you edited anything in the full state
 */
const emit = defineEmits(['nextStep', 'previousStep', 'close']);

/** use stateData to check if there's old values for this state and if they have changed */
const { [STEP_ADD_CLIENT_GOAL]: stateData } = props.state;

const { t } = useI18n();
const { required, requiredIf, helpers } = useValidators();
const account = useCurrentAccount();
const { trackGoalBasedClientDropdownOptionSelected, trackGoalBasedClientTypeSelection } = useOnboardingWizardTracking();

const count = shallowRef(10);
const pageSize = shallowRef(10);

const clientsState = useClientsV3Loader({ count, pageSize, params: { excludeOwnerCompany: true } });
const { items: clients, inSync: clientsInSync } = clientsState;
const selectedClientId = shallowRef(stateData?.selectedClientId);
const hasNoClients = computed(() => clients.value.length === 0 && clientsInSync.value);
const newClientName = shallowRef(stateData?.newClientName ?? '');
const isClientProject = shallowRef(stateData?.clientProject !== undefined ? stateData.clientProject : undefined);
const inputEl = shallowRef(null);
const showClientNameInput = shallowRef(Boolean(isClientProject.value && newClientName.value));
const clientInputLabelText = computed(() => (hasNoClients.value ? t('Add name') : t('Add a new client')));
const selectedClientName = computed(() =>
  isClientProject.value
    ? clients.value.find((client) => client.id === selectedClientId.value)?.name
    : account.value.name,
);

const rules = {
  isClientProject: {
    required,
  },
  newClientName: {
    required: helpers.withMessage(
      t("Please enter your client's company name"),
      requiredIf(() => isClientProject.value && (showClientNameInput.value || hasNoClients.value)),
    ),
  },
  selectedClientId: {
    required: helpers.withMessage(
      t('Please select a client or add new client'),
      requiredIf(() => isClientProject.value && !hasNoClients.value && !showClientNameInput.value),
    ),
  },
};
const v$ = useVuelidate(rules, { newClientName, selectedClientId, isClientProject }, { $autoDirty: false });

function nextStep() {
  v$.value.$touch();
  if (!v$.value.$error) {
    // reset selected client from dropdown if you pick internal after you click continue
    // resetting it on selecting internal would be too soon and annoying
    if (!isClientProject.value) {
      selectedClientId.value = undefined;
    }

    const payload = {
      newClientName: newClientName.value || '',
      clientProject: isClientProject.value,
      selectedClientId: selectedClientId.value,
      selectedClientName: selectedClientName.value,
    };

    emit('nextStep', JSON.stringify(payload) !== JSON.stringify(stateData), payload);
  }
}

function previousStep() {
  emit('previousStep');
}

function skipStep() {
  // no action
}

async function toggleNewClientInput(show) {
  if (show) {
    trackGoalBasedClientDropdownOptionSelected('add_new_client');
    v$.value.$reset();
    selectedClientId.value = undefined;
    showClientNameInput.value = true;
    await nextTick();
    inputEl.value.focus();
  } else {
    newClientName.value = '';
    showClientNameInput.value = false;
  }
}

function clientSelected() {
  if (selectedClientId.value) {
    trackGoalBasedClientDropdownOptionSelected('existing_client');
  }
}

watch(newClientName, (newValue) => {
  newClientName.value = capitalizeFirstLetter(newValue);
});

watch(isClientProject, async (newValue, oldValue) => {
  trackGoalBasedClientTypeSelection(newValue ? 'client' : 'internal');
  v$.value.$reset();
  if (newValue === false) {
    newClientName.value = '';
  }
  if (!oldValue && newValue) {
    newClientName.value = '';
    if (hasNoClients.value) {
      await nextTick();
      inputEl.value.focus();
    }
  }
});
</script>

<template>
  <OnboardingWizardCommonStep
    :title="t('Is this project for a client or for internal use?')"
    :description="t('Your project will be set up under the company you select here.')"
  >
    <LscChoice
      hasCheck
      icon="lsi-assignees"
      class="my-3"
      :class="{ '!border-critical-default': v$.isClientProject.$error }"
      :selected="isClientProject === true"
      :label="t('Client')"
      :description="t('Manage projects with partners and clients on shared tasks.')"
      @click="isClientProject = true"
    />
    <div v-if="isClientProject">
      <VSelect
        v-if="!showClientNameInput && !hasNoClients"
        v-model="selectedClientId"
        class="my-3"
        :items="clients"
        itemTitle="name"
        itemValue="id"
        :label="t('Select client')"
        :menuProps="{ width: 'auto' }"
        :loading="!clientsInSync"
        :errorMessages="v$.selectedClientId.$errors.map((error) => error.$message)"
        @update:modelValue="clientSelected"
      >
        <template #prepend-item>
          <VListItem link class="a text-primary" @click="toggleNewClientInput(true)">
            <template #prepend>
              <LscIcon icon="lsi-add" />
            </template>
            <VListItemTitle class="flex">{{ t('Add new client') }}</VListItemTitle>
          </VListItem>
        </template>
        <template #append-item>
          <WidgetLoadingLazy v-model:count="count" :state="clientsState" :step="pageSize" />
        </template>
      </VSelect>
      <VTextField
        v-else
        ref="inputEl"
        v-model="newClientName"
        :label="clientInputLabelText"
        :placeholder="t('e.g. {company}', { company: 'Acme Inc' })"
        :errorMessages="v$.newClientName.$errors.map((error) => error.$message)"
        :maxLength="255"
        :clearable="false"
        :appendIcon="!hasNoClients ? 'lsi-close' : undefined"
        class="my-3"
        @click:append="toggleNewClientInput(false)"
        @blur="newClientName = newClientName.trim()"
      />
    </div>
    <LscChoice
      hasCheck
      icon="lsi-project"
      class="my-3"
      :class="{ '!border-critical-default': v$.isClientProject.$error }"
      :selected="isClientProject === false"
      :label="t('Internal')"
      :description="t('Manage tasks and projects within your own company.')"
      :errorMessages="v$.isClientProject.$errors.map((error) => error.$message)"
      @click="isClientProject = false"
    />

    <div
      v-if="v$.isClientProject.$error"
      class="flex items-center text-body-2 text-critical"
      role="alert"
      aria-live="polite"
    >
      <LscIcon icon="lsi-customfield-status" size="sm" class="mr-1" />
      {{ t('Select a project type') }}
    </div>

    <slot name="underFieldsButtons" :nextButtonText="nextButtonText" :nextStep="nextStep" />
    <template #right>
      <OnboardingWizardCommonPreview
        :clientCompanyName="newClientName || selectedClientName"
        :preselectedTab="PREVIEW_TAB_TABLE"
        :tabs="[PREVIEW_TAB_TABLE, PREVIEW_TAB_BOARD, PREVIEW_TAB_LIST]"
        :tabsClickable="false"
      />
    </template>
  </OnboardingWizardCommonStep>
  <slot
    name="footerButtons"
    :nextButtonText="nextButtonText"
    :nextStep="nextStep"
    :skipButtonText="t('Skip')"
    :showSkipButton="showSkipButton"
    :skipStep="skipStep"
    :previousStep="previousStep"
  />
</template>
