<script setup>
import { JobRolesAddDialog } from '@/module/jobRoles';
import LscMenu from '../../../components/overlay/menu/LscMenu.vue';
import LswRolePickerMenuCard from './LswRolePickerMenuCard.vue';

defineProps({
  dataTestIdPrefix: {
    type: String,
    default: undefined,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  people: {
    type: Array,
    default: () => [],
  },
  isModifyingPrimaryRole: {
    type: Boolean,
    default: false,
  },
  canAssignUsers: {
    type: Boolean,
    default: true,
  },
  showNone: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['clearRole']);

const modelValue = defineModel({
  type: Boolean,
  default: false,
});

const roles = defineModel('roles', {
  type: Array,
  default: () => [],
});

const isDialogOpen = shallowRef(false);
</script>

<template>
  <LscMenu v-bind="$attrs" v-model="modelValue" :disabled="disabled">
    <template #activator="activator">
      <slot name="activator" v-bind="activator" />
    </template>
    <LswRolePickerMenuCard
      v-model:roles="roles"
      :dataTestIdPrefix
      :showNone="showNone"
      @addRole="isDialogOpen = true"
      @clearRole="emit('clearRole')"
    >
      <template v-if="$slots.header" #header><slot name="header" /></template>
    </LswRolePickerMenuCard>
  </LscMenu>

  <JobRolesAddDialog
    v-if="isDialogOpen"
    v-model="isDialogOpen"
    :canSaveAndAddAnother="false"
    :canAssignUsers="canAssignUsers"
    :people="people"
  />
</template>
