<script setup>
import { useHotjar } from '@/api';
import { useI18n } from '@/util';
import OnboardingWizardCommonChoiceExtended from '../common/OnboardingWizardCommonChoiceExtended.vue';
import OnboardingWizardCommonStep from '../common/OnboardingWizardCommonStep.vue';
import { STEP_SELECT_GOAL } from '../constants';

const props = defineProps({
  nextButtonText: {
    type: String,
    required: true,
  },
  state: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['nextStep']);

const { t } = useI18n();
const { trackClick } = useHotjar();

const { [STEP_SELECT_GOAL]: stateData } = props.state;
const goals = [
  {
    id: 1,
    icon: 'lsi-group',
    label: t('I want to boost team productivity and resource capacity'),
    description: t("How can I manage my team's workload and optimize their time efficiency?"),
  },
  {
    id: 2,
    icon: 'lsi-billable',
    label: t('I want to maximize billable work and revenue potential'),
    description: t('How can I track billable hours and improve profitability?'),
  },
  {
    id: 3,
    icon: 'lsi-insights',
    label: t('I want to gain insights into progress and performance'),
    description: t('How can I monitor progress and make data-driven decisions?'),
  },
];

const selectedGoalId = shallowRef(stateData?.selectedGoalId);

function nextStep() {
  emit('nextStep', !stateData || stateData?.selectedGoalId !== selectedGoalId.value, {
    selectedGoalId: selectedGoalId.value ?? -1,
  });
}

function skipStep() {
  emit('nextStep', !stateData || !stateData?.selectedGoalId !== -1, {
    selectedGoalId: -1,
    skipped: true,
  });
}
onMounted(() => {
  trackClick('ONBOARDING_20_PLUS');
});
</script>

<template>
  <OnboardingWizardCommonStep class="flex items-center">
    <template #title>
      <span class="text-h2 font-semibold">
        {{ t('What do you want to explore first in {teamwork}?', { teamwork: 'Teamwork.com' }) }}
      </span>
    </template>
    <template #description>
      <div class="-mt-2 text-body-1">
        {{ t("Your selection won't limit what you can do, but it will help us to personalize your experience.") }}
      </div>
    </template>
    <div class="max-w-[--onboarding-wizard-select-goal-step-container-max-width]">
      <LscChoiceList v-model="selectedGoalId" :multiple="false" :mandatory="true" :limit="1">
        <OnboardingWizardCommonChoiceExtended
          v-for="{ id, icon, label, description } in goals"
          :key="id"
          :value="id"
          :label="label"
          :description="description"
        >
          <template #image>
            <LscIconButton :icon="icon" size="lg" variant="primary" />
          </template>
        </OnboardingWizardCommonChoiceExtended>
      </LscChoiceList>
      <slot
        name="underFieldsButtons"
        :isNextStepButtonDisabled="selectedGoalId === undefined"
        :nextButtonText="t('Next')"
        :nextStep="nextStep"
        :showSkipButton="true"
        :skipButtonText="t('I\'m not sure yet')"
        :skipStep="skipStep"
        :reverse="true"
      />
    </div>
  </OnboardingWizardCommonStep>
</template>
