import * as TOKENS from '@teamwork/lightspeed-design-tokens';
import { DateTime } from 'luxon';
import { useRouter } from 'vue-router';
import {
  useAllocationsActions,
  useClientActions,
  useCohort,
  useCompaniesActions,
  useCurrentAccount,
  useCurrentUser,
  useExperimentA2301,
  useExperimentA2420,
  useFeatures,
  useJobRoleActions,
  usePersonActions,
  usePreferences,
  usePricePlan,
  useProjectActions,
  useTaskActions,
  useTasklistsV3Loader,
  useTasksV3Loader,
  useTemplatesActions,
  useTrashcanActions,
  useUserRateActions,
} from '@/api';
import { useCurrentProject, useRoute } from '@/route';
import { until, useI18n } from '@/util';
import { grantAccessTo } from '@/module/lockdown';

export function useSampleProjects(shouldFetchTasks = false) {
  const currentProject = useCurrentProject();
  const toast = useLsToast();
  const { projectsDummyDataEnabled, projectsDummyDataV3Enabled } = useFeatures();
  const {
    sampleProjectsBannerDismissed,
    sampleProjectsVisible,
    sampleProjectIds,
    sampleUserIds,
    sampleClientIds,
    sampleRoles,
    sampleProjectsRemovedDuringCheckout,
  } = usePreferences();
  const account = useCurrentAccount();
  const { createAllocation } = useAllocationsActions();
  const { deleteProject, createProjectUpdate, createTentativeProject } = useProjectActions();
  const { attachUserToJobRole, createJobRole, deleteJobRole } = useJobRoleActions();
  const { createProjectFromSampleTemplate } = useTemplatesActions();
  const { updateUserCost, updateUserRate } = useUserRateActions();
  const { deletePeople, sendInvite, updatePerson } = usePersonActions();
  const { restoreItem } = useTrashcanActions();
  const { createCompany } = useCompaniesActions();
  const { deleteClient, restoreClient } = useClientActions();
  const router = useRouter();
  const route = useRoute();
  const { t } = useI18n();
  const { isPaid } = usePricePlan();
  const { isICP, isCompanySizeAboveTen } = useCohort();
  const { isExpA2301Variation } = useExperimentA2301();
  const { isExpA2420Variation } = useExperimentA2420();
  const currentUser = useCurrentUser();
  const { updateTask } = useTaskActions();

  const resourceProjectID = shallowRef();
  const resourceTasklistID = shallowRef();

  const expA27AppLevelTargeting = computed(() => isICP.value && isCompanySizeAboveTen.value);

  const isSampleProjectRoute = computed(() => {
    return (
      route.matched.some(({ meta }) => meta.sampleProjectsBannerVisible) &&
      currentProject.value != null &&
      sampleProjectIds.value.includes(currentProject.value.id)
    );
  });

  const isExpA27Variation = computed(() => projectsDummyDataEnabled.value && expA27AppLevelTargeting.value);
  const isExpA27V3Variation = computed(() => projectsDummyDataV3Enabled.value && isExpA27Variation.value);

  const isSampleProjectsBannerRoute = computed(
    () =>
      (route.matched.some(({ meta }) => meta.sampleProjectsBannerVisible) &&
        currentProject.value == null &&
        !route.meta.sampleProjectsBannerVisibleExcludeChildrenPath) ||
      isSampleProjectRoute.value,
  );

  const isSampleProjectsBannerVisible = computed(
    () =>
      (isExpA27Variation.value || isExpA2420Variation.value) &&
      isSampleProjectsBannerRoute.value &&
      sampleProjectIds.value.length > 0,
  );

  const areSampleProjectsVisible = computed(() => sampleProjectsVisible.value.length > 0);

  // #region Sample Project Data
  const sampleTemplates = {
    standard: [
      { id: 45, name: '(Fixed-fee Sample Project) Project Management Plan' },
      { id: 46, name: '(Retainer Sample Project) Digital Marketing Plan' },
      { id: 47, name: '(Time & Expenses Sample Project) Creative Requests' },
    ],
    resources: [{ id: 48, name: '(Resource Management Sample Project) Website build - eCommerce' }],
  };

  const sampleClients = {
    standard: [
      {
        name: '(Sample Client A)',
        clientSampleProjectIds: [45],
      },
      {
        name: '(Sample Client B)',
        clientSampleProjectIds: [46],
      },
    ],
    resources: [
      {
        name: '(Sample Client A)',
        clientSampleProjectIds: [48],
      },
    ],
  };

  const sampleUsersList = {
    standard: [
      { email: 'pm@teamwork.sample', firstName: 'Project', lastName: 'Manager (Sample User)' },
      { email: 'marketing@teamwork.sample', firstName: 'Marketing', lastName: 'Specialist (Sample User)' },
    ],
    resources: [
      { email: 'sample_designer_david@teamwork.sample', firstName: 'David', lastName: 'UI Designer (Sample User)' },
      { email: 'sample_designer_sarah@teamwork.sample', firstName: 'Sarah', lastName: 'UX Designer (Sample User)' },
      { email: 'sample_developer@teamwork.sample', firstName: 'Joana', lastName: 'Developer (Sample User)' },
    ],
  };

  // Last two digits are decimals (70.00)
  // cost rate is 60% of billableRate
  const userBillableRate = 5000;
  const userCostRate = 3000;

  const allocations = [
    {
      assignedUserID: currentUser.value.id,
      color: 'afdff9',
      description: '',
      distributeType: 'distributed',
      duration: 480,
      hoursPerDay: 8,
      ignoreCollisions: false,
      projectId: 0,
      startedAt: DateTime.now().startOf('month').plus({ days: 1 }).toFormat('yyyy-MM-dd'),
      endedAt: DateTime.now().startOf('month').plus({ days: 2 }).toFormat('yyyy-MM-dd'),
      title: 'Initial',
    },
    {
      assignedUserID: currentUser.value.id,
      color: 'f7a8c1',
      description: '',
      distributeType: 'distributed',
      duration: 480,
      hoursPerDay: 8,
      ignoreCollisions: false,
      projectId: 0,
      startedAt: DateTime.now().startOf('month').plus({ days: 14 }).toFormat('yyyy-MM-dd'),
      endedAt: DateTime.now().startOf('month').plus({ days: 16 }).toFormat('yyyy-MM-dd'),
      title: 'Planning',
    },
    {
      assignedUserID: currentUser.value.id,
      color: '97f7a9',
      description: '',
      distributeType: 'distributed',
      duration: 480,
      hoursPerDay: 8,
      ignoreCollisions: false,
      projectId: 0,
      startedAt: DateTime.now().endOf('month').minus({ days: 5 }).toFormat('yyyy-MM-dd'),
      endedAt: DateTime.now().endOf('month').minus({ days: 3 }).toFormat('yyyy-MM-dd'),
      title: 'Retrospective',
    },
  ];

  const tentativeProjectAllocations = [
    {
      assignedUserID: 0,
      color: TOKENS.LsdsCColorPickerSchedulerColor8,
      description: '',
      distributeType: 'distributed',
      duration: 10080,
      hoursPerDay: 8,
      ignoreCollisions: true,
      projectId: 0,
      startedAt: DateTime.now().startOf('week').plus({ days: 1 }).toFormat('yyyy-MM-dd'),
      endedAt: DateTime.now().startOf('week').plus({ days: 29 }).toFormat('yyyy-MM-dd'),
      title: 'Development & QA',
    },
    {
      assignedUserID: 0,
      color: TOKENS.LsdsCColorPickerSchedulerColor8,
      description: '',
      distributeType: 'distributed',
      duration: 7680,
      hoursPerDay: 8,
      ignoreCollisions: true,
      projectId: 0,
      startedAt: DateTime.now().startOf('week').plus({ days: 1 }).toFormat('yyyy-MM-dd'),
      endedAt: DateTime.now().startOf('week').plus({ days: 21 }).toFormat('yyyy-MM-dd'),
      title: 'Development time',
    },
  ];

  const projectHealthUpdates = [
    {
      health: 1,
      text: 'Tasks are taking much longer than the time estimated for them. Assess if project requires a reassessment of estimates or if the over run has been due to an unforeseen circumstance not accounted for during planning.',
    },
    {
      health: 2,
      text: 'Planning stage is on track and ready to deliver to schedule. Potential delays due to non-responsive client for payment set up. Finance have to follow up with account manager/sales manager should they be unable to establish contact by next Wednesday.',
    },
    {
      health: 3,
      text: 'Steady rate of requests coming in for team to complete with minimal tasks falling outside of scope or time estimate. Review resource requirements should requests extend beyond 30 per week.',
    },
  ];

  const tentativeProjects = [
    {
      name: 'Proposal - SEO Audit',
      people: '',
    },
  ];

  const jobRoles = [
    {
      name: 'UI Designer',
    },
    {
      name: 'UX Designer',
    },
    {
      name: 'Developer',
    },
  ];

  const resourceTasks = [
    {
      startDate: DateTime.now().startOf('week').plus({ days: 4 }),
      dueDate: DateTime.now().startOf('week').plus({ days: 4 }),
      estimateMinutes: 360,
      assignedUsersIndex: [0],
    },
    {
      startDate: DateTime.now().startOf('week').plus({ days: 4 }),
      dueDate: DateTime.now().startOf('week').plus({ days: 4 }),
      estimateMinutes: 240,
      assignedUsersIndex: [0],
    },
    {
      startDate: DateTime.now().startOf('week').plus({ days: 4 }),
      dueDate: DateTime.now().startOf('week').plus({ days: 4 }),
      estimateMinutes: 120,
      assignedUsersIndex: [1],
    },
    {
      startDate: DateTime.now().startOf('week').plus({ days: 7 }),
      dueDate: DateTime.now().startOf('week').plus({ days: 8 }),
      estimateMinutes: 360,
      assignedUsersIndex: [0],
    },
    {
      startDate: DateTime.now().startOf('week').plus({ days: 8 }),
      dueDate: DateTime.now().startOf('week').plus({ days: 9 }),
      estimateMinutes: 360,
      assignedUsersIndex: [0],
    },
    {
      startDate: DateTime.now().startOf('week').plus({ days: 15 }),
      dueDate: DateTime.now().startOf('week').plus({ days: 15 }),
      estimateMinutes: 90,
      assignedUsersIndex: [0, 1],
    },
    {
      startDate: DateTime.now().startOf('week').plus({ days: 15 }),
      dueDate: DateTime.now().startOf('week').plus({ days: 20 }),
      estimateMinutes: 840,
      assignedUsersIndex: [0, 1],
    },
    {
      startDate: DateTime.now().startOf('week').plus({ days: 20 }),
      dueDate: DateTime.now().startOf('week').plus({ days: 21 }),
      estimateMinutes: 120,
      assignedUsersIndex: [1],
    },
    {
      startDate: DateTime.now().startOf('week').plus({ days: 20 }),
      dueDate: DateTime.now().startOf('week').plus({ days: 22 }),
      estimateMinutes: 180,
      assignedUsersIndex: [1],
    },
    {
      startDate: DateTime.now().startOf('week').plus({ days: 21 }),
      dueDate: DateTime.now().startOf('week').plus({ days: 28 }),
      estimateMinutes: 180,
      assignedUsersIndex: [0, 1],
    },
  ];

  // #endregion Sample Project Data

  // note: this fails if the sample client companies already exist
  async function createSampleClients({ type = 'standard' } = {}) {
    return Promise.all(
      sampleClients[type].map(async ({ name, clientSampleProjectIds }) =>
        createCompany({ company: { name } })
          .then(({ id }) => ({ id, clientSampleProjectIds }))
          .catch(() => {}),
      ),
    );
  }

  async function createSampleUsers() {
    async function createSampleUser(email, firstName, lastName) {
      return sendInvite({
        person: {
          'email-address': email,
          'first-name': firstName,
          'last-name': lastName,
          autoGiveProjectAccess: true,
          sendInvite: false,
        },
      })
        .then(({ data }) => data.id)
        .catch(() => {});
    }

    const sampleUsers = await Promise.all(
      sampleUsersList.standard.map((user) => createSampleUser(user.email, user.firstName, user.lastName)),
    );

    sampleUserIds.value = sampleUsers.filter(Boolean).map((id) => Number(id));
    const sampleProjectUserAId = sampleUserIds.value[0];
    const sampleProjectUserBId = sampleUserIds.value[1];

    if (Number.isInteger(sampleProjectUserAId) && Number.isInteger(sampleProjectUserBId)) {
      await Promise.allSettled([
        // autoGiveProjectAccess doesn't work on initial user creation, so we need to update them manually
        updatePerson({ id: sampleProjectUserAId, autoGiveProjectAccess: true }),
        updatePerson({ id: sampleProjectUserBId, autoGiveProjectAccess: true }),

        // Set billable and cost rates for sampleProjectUserAId
        updateUserRate({ userId: sampleProjectUserAId, userRate: 12000 }),
        updateUserCost({ userId: sampleProjectUserAId, userCost: 8500 }),
        updateUserRate({ userId: sampleProjectUserBId, userRate: 10000 }),
        updateUserCost({ userId: sampleProjectUserBId, userCost: 7000 }),
      ]);
    }

    return {
      sampleProjectUserAId,
      sampleProjectUserBId,
    };
  }

  async function createSampleResourceUsers() {
    async function createSampleUser(email, firstName, lastName) {
      return sendInvite({
        person: {
          'email-address': email,
          'first-name': firstName,
          'last-name': lastName,
          autoGiveProjectAccess: true,
          sendInvite: false,
        },
      })
        .then(({ data }) => data.id)
        .catch(() => {});
    }

    const sampleUsers = await Promise.all(
      sampleUsersList.resources.map((user) => createSampleUser(user.email, user.firstName, user.lastName)),
    );

    sampleUserIds.value = sampleUsers.filter(Boolean).map((id) => Number(id));

    if (sampleUserIds.value.length > 0) {
      await Promise.allSettled([
        sampleUserIds.value.forEach((id) => updatePerson({ id, autoGiveProjectAccess: true })),
      ]);
    }
    return sampleUserIds.value;
  }

  async function updateInstallationsRate(userRate, userCost) {
    await Promise.all([
      updateUserRate({ userId: currentUser.value.id, userRate }),
      updateUserCost({ userId: currentUser.value.id, userCost }),
    ]).catch((error) => {
      if (import.meta.env.DEV) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    });
  }

  function updateSampleProjectsIdsPreferences(projectIds, overwriteIds) {
    if (overwriteIds) {
      sampleProjectIds.value = projectIds;
    }
    sampleProjectsVisible.value = projectIds;
  }

  function dismissSampleProjectsBanner() {
    sampleProjectsBannerDismissed.value = true;
  }

  async function deleteSampleData({ onCheckout = false } = {}) {
    // Ordering matters – Delete users first
    if (areSampleProjectsVisible.value) {
      if (sampleUserIds.value.length > 0) {
        const unassignFromAll = false;
        await deletePeople(sampleUserIds.value, unassignFromAll).catch(() => {
          if (!onCheckout) {
            toast.critical(t('There was a problem hiding sample users'));
          }
        });
      }

      // Delete job roles after users
      const deleteRoles = [];
      for (const role of sampleRoles.value) {
        deleteRoles.push(deleteJobRole({ id: role.id }));
      }
      await Promise.allSettled(deleteRoles);

      // Delete projects next
      const deleteProjects = [];
      for (const sampleProjectId of sampleProjectIds.value) {
        deleteProjects.push(deleteProject({ id: sampleProjectId }));
      }
      sampleProjectsVisible.value = [];
      await Promise.all(deleteProjects)
        .then(() => {
          if (isSampleProjectRoute.value) {
            router.push('/projects/list');
          }
          if (onCheckout) {
            sampleProjectsRemovedDuringCheckout.value = true;
          } else {
            toast.success(t('All sample projects were hidden'));
          }
        })
        .catch(() => {
          sampleProjectsVisible.value = sampleProjectIds.value;
          if (!onCheckout) {
            toast.critical(t('There was a problem hiding sample projects'));
          }
        });

      // Delete clients after projects
      const deleteClients = [];
      for (const sampleClientId of sampleClientIds.value) {
        deleteClients.push(deleteClient(sampleClientId));
      }
      await Promise.allSettled(deleteClients);
    }
  }

  async function restoreSampleProjects({ onLoad } = {}) {
    // Order matters – restore clients first
    const restoreClients = [];
    for (const sampleClientId of sampleClientIds.value) {
      restoreClients.push(restoreClient(sampleClientId));
    }
    await Promise.allSettled(restoreClients);

    // Restore users before projects
    const restoreUsers = [];
    for (const sampleUserId of sampleUserIds.value) {
      restoreUsers.push(restoreItem({ type: 'people', id: sampleUserId }));
    }
    await Promise.allSettled(restoreUsers);

    if (isExpA2420Variation.value && sampleRoles.value.length > 0) {
      // Restore job roles after users and attach roles to users
      await createAndAssignJobRoles({ sampleUsers: sampleUserIds.value });
    }

    // Restore projects last
    const restoreProjects = [];
    for (const sampleProjectId of sampleProjectIds.value) {
      restoreProjects.push(restoreItem({ type: 'projects', id: sampleProjectId }));
    }
    await Promise.allSettled(restoreProjects).then(() => {
      sampleProjectsVisible.value = sampleProjectIds.value;
      if (!onLoad) {
        toast.success(t('All sample projects are visible'));
      }
    });
  }

  async function checkIsPaidAfterCheckout() {
    if (isPaid.value) {
      await deleteSampleData({ onCheckout: true });
    } else if (sampleProjectsRemovedDuringCheckout.value) {
      await restoreSampleProjects({ onLoad: true }).then(() => {
        sampleProjectsRemovedDuringCheckout.value = false;
      });
    }
  }

  async function setWorkingHours() {
    try {
      updatePerson({
        id: currentUser.value.id,
        workingHours: {
          entries: [
            { weekday: 'monday', taskHours: 8 },
            { weekday: 'tuesday', taskHours: 8 },
            { weekday: 'wednesday', taskHours: 8 },
            { weekday: 'thursday', taskHours: 8 },
            { weekday: 'friday', taskHours: 8 },
            { weekday: 'saturday', taskHours: 0 },
            { weekday: 'sunday', taskHours: 0 },
          ],
        },
      });
    } catch (error) {
      if (import.meta.env.DEV) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  }

  async function createSampleClientsAndUsers({ type = 'standard' } = {}) {
    const sampleUsers = type === 'standard' ? await createSampleUsers() : await createSampleResourceUsers();
    const createdSampleClients = await createSampleClients({ type });

    sampleClientIds.value = createdSampleClients.map((sampleClient) => Number(sampleClient?.id) || undefined);
    return {
      sampleUsers,
      createdSampleClients,
    };
  }

  async function createSampleProjects({ sampleUsers, createdSampleClients, type = 'standard' } = {}) {
    sampleProjectIds.value = [];

    const templatesPromises = [];
    sampleTemplates[type].forEach((template) => {
      const companyId = createdSampleClients?.find(({ clientSampleProjectIds } = {}) =>
        clientSampleProjectIds?.includes(template.id),
      )?.id;
      const payload = {
        templateId: template.id,
        installationId: account.value.id,
        sourceInstallationId: account.value.id,
        projectName: template.name,
        cloneProjectName: template.name,
        owner: { id: currentUser.value.id },
        activeView: 'table',
        startPage: 'table',
        companyId,
        cloneprojectAction: 'copy',
        ...sampleUsers,
      };
      templatesPromises.push(createProjectFromSampleTemplate(payload));
    });

    const sampleProjects = await Promise.all(templatesPromises).catch((error) => {
      if (import.meta.env.DEV) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    });

    const projectIDs = [];
    if (sampleProjects?.length > 0) {
      sampleProjects?.forEach(({ data }) => {
        projectIDs.push(parseInt(data.projectId, 10));
        updateSampleProjectsIdsPreferences([...sampleProjectIds.value, parseInt(data.projectId, 10)], true);
      });
    }

    if (sampleProjects?.length > 1) {
      const setupSampleProjectsPromises = [];
      sampleProjects?.forEach(({ data }, index) => {
        updateSampleProjectsIdsPreferences([...sampleProjectIds.value, parseInt(data.projectId, 10)], true);

        const projectHealthUpdate = projectHealthUpdates[index % projectHealthUpdates.length]; // wraps around if there are more projects than updates

        const updateObject = {
          update: { ...projectHealthUpdate },
        };

        const allocation = { ...allocations[index] };
        allocation.projectId = parseInt(data.projectId, 10);

        setupSampleProjectsPromises.push(createProjectUpdate(data.projectId, updateObject));
        setupSampleProjectsPromises.push(createAllocation({ allocation }));
      });
      if (!isExpA2301Variation.value) {
        setupSampleProjectsPromises.push(updateInstallationsRate(userBillableRate, userCostRate));
      }
      setupSampleProjectsPromises.push(setWorkingHours());

      // set banner un-dismissed, to be sure
      sampleProjectsBannerDismissed.value = false;

      await Promise.all(setupSampleProjectsPromises).catch((error) => {
        if (import.meta.env.DEV) {
          // eslint-disable-next-line no-console
          console.error(error);
        }
      });
    }

    return projectIDs;
  }

  async function createResourceAllocations({ projects, user }) {
    const allocationPromises = [];

    projects.forEach((id, i) => {
      const allocation = {
        ...tentativeProjectAllocations[i],
        assignedUserID: user, //  usersToAdd[usersToAdd.length - 1] for joanna
        projectId: id,
      };
      allocationPromises.push(createAllocation({ allocation }));
    });

    return Promise.all(allocationPromises).catch((error) => {
      if (import.meta.env.DEV) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    });
  }

  async function createSampleTentativeProjects({ sampleUsers, sampleProjects }) {
    const usersToAdd = Object.values(sampleUsers);
    const tentativeProjectPromises = [];

    tentativeProjects.forEach((tp) => {
      const payload = tp;
      payload.people = usersToAdd.join(',');
      tentativeProjectPromises.push(createTentativeProject(payload));
    });

    const sampleTentativeProjects = await Promise.all(tentativeProjectPromises).catch((error) => {
      if (import.meta.env.DEV) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    });

    if (sampleTentativeProjects?.length > 0) {
      sampleTentativeProjects.forEach((id) => {
        sampleProjects.push(parseInt(id, 10));
        updateSampleProjectsIdsPreferences([...sampleProjectIds.value, parseInt(id, 10)], true);
      });
    }

    await createResourceAllocations({ projects: sampleProjects, user: usersToAdd[usersToAdd.length - 1] });
  }

  async function assignUsersToRoles(roles = [], users = []) {
    const attachedRolesPromise = [];
    sampleRoles.value = [];

    if (roles.length > 0) {
      roles.forEach((role, i) => {
        const user = {
          id: Object.values(users)[i],
        };
        attachedRolesPromise.push(attachUserToJobRole(role, user, true));
        sampleRoles.value = [...sampleRoles.value, { name: role.name, id: role.id }];
      });
    }

    return Promise.all(attachedRolesPromise).catch((error) => {
      if (import.meta.env.DEV) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    });
  }

  async function createAndAssignJobRoles({ sampleUsers }) {
    const rolePromises = [];
    jobRoles.forEach((jr) => {
      rolePromises.push(createJobRole(jr));
    });

    const newJobRoles = await Promise.all(rolePromises).catch((error) => {
      if (import.meta.env.DEV) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    });

    return assignUsersToRoles(newJobRoles, sampleUsers);
  }

  const tasklistState = useTasklistsV3Loader({
    count: shouldFetchTasks ? Infinity : -1,
    pageSize: 500,
    projectId: computed(() => resourceProjectID.value || null),
    params: {
      status: 'all',
      sort: 'displayOrder',
    },
  });
  const { items: tasklists, loaded: tasklistsLoaded } = tasklistState;

  const tasksState = useTasksV3Loader({
    count: shouldFetchTasks ? Infinity : -1,
    pageSize: 500,
    tasklistId: computed(() => resourceTasklistID.value || null),
    params: {
      sort: 'displayOrder',
    },
  });
  const { items: tasks, loaded: tasksLoaded } = tasksState;

  async function assignUsersToTasks({ sampleProjects, sampleUsers }) {
    const users = sampleUsers?.slice(0, sampleUsers.length - 1);

    resourceProjectID.value = sampleProjects[0];
    await until(tasklistsLoaded, true);

    resourceTasklistID.value = tasklists.value[0]?.id;
    await until(tasksLoaded, true);

    return Promise.allSettled([
      tasks.value.forEach((task, taskIndex) => {
        const grantAccessToString = grantAccessTo({
          users: users.map((id) => {
            return { id };
          }),
          everyone: false,
        });

        const assignees = users
          .map((id, userIndex) => {
            return resourceTasks[taskIndex].assignedUsersIndex.includes(userIndex) ? { id } : null;
          })
          .filter((a) => a?.id);
        delete resourceTasks[taskIndex].assignedUsersIndex;

        return updateTask({
          id: task.id,
          grantAccessTo: grantAccessToString,
          assignees,
          ...resourceTasks[taskIndex],
        });
      }),
    ]);
  }

  return {
    isExpA27Variation,
    isExpA27V3Variation,
    isSampleProjectsBannerVisible,
    areSampleProjectsVisible,
    dismissSampleProjectsBanner,
    deleteSampleData,
    restoreSampleProjects,
    checkIsPaidAfterCheckout,
    createSampleClientsAndUsers,
    createSampleProjects,
    createSampleTentativeProjects,
    createAndAssignJobRoles,
    createSampleUsers,
    assignUsersToTasks,
  };
}
