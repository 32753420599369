export const GOALS_WITH_TOURS = [-1, 1];

export const GOAL_TOUR_MAPPING = {
  1: {
    firstTour: 30,
    tours: [30],
  },
  '-1': {
    firstTour: 30,
    tours: [30],
  },
};
