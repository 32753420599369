<script setup>
import { useIntersectionObserver } from '@vueuse/core';
import { VSlideXTransition } from 'vuetify/components';
import { useFeatures, usePermissions } from '@/api';
import { useI18n } from '@/util';
import { customfieldTypeIcons } from '@/module/customfield';
import { useReportCustomBuilder } from '../useReportCustomBuilder.js';
import ReportCustomBuilderOptionsColumn from './ReportCustomBuilderOptionsColumn.vue';
import ReportCustomBuilderOptionsCustomfieldDialog from './ReportCustomBuilderOptionsCustomfieldDialog.vue';

const props = defineProps({
  validation: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(['handleCustomReportTrack']);

const { t } = useI18n();
const {
  currentReport,
  reportColumns,
  selectedColumns,
  reachedMaxNumberColumns,
  isAdvancedReporting,
  currentReportBuilderAdvancedColumns,
  reportBuilderColumnCategories,
  formatSingleColumn,
} = useReportCustomBuilder();

const { canManageCustomfields } = usePermissions();
const { formulaFieldsEnabled } = useFeatures();

const creatingFormula = shallowRef(false);

const searchInput = shallowRef('');
const count = shallowRef(20);
const editingCustomFieldId = shallowRef(null);
const draggedItem = shallowRef(null);
const scrollRef = shallowRef(null);
const showColumnPopover = shallowRef(false);

const showValidationError = computed(() => props.validation?.$errors?.length > 0);

const canEditCustomfields = computed(
  () => canManageCustomfields.value && ['task', 'project'].includes(currentReport.value.type),
);

const sortedColumns = computed(() =>
  reportColumns.value.toSorted((a, b) => a.position - b.position).sort((a, b) => a.groupIndex - b.groupIndex),
);

const filteredColumns = computed(() => {
  if (!searchInput.value) {
    return sortedColumns.value;
  }

  const search = searchInput.value.trim().toLowerCase();

  return sortedColumns.value.filter((column) => column.name.toLowerCase().includes(search));
});

const groupedReportColumns = computed(() => {
  const groupedMap = {};
  const groups = [];

  if (filteredColumns.value.length === 0) {
    return groups;
  }

  const columns = filteredColumns.value.slice(0, count.value);

  for (let i = 0; i < columns.length; i++) {
    const column = columns[i];
    const groupIndex = groupedMap[column.category] ?? -1;

    if (groupIndex > -1) {
      groups[groupIndex].items.push(column);
    } else {
      const length = groups.push({
        category: column.category,
        key: column.category,
        items: [column],
      });
      groupedMap[column.category] = length - 1;
    }
  }

  return groups;
});

useIntersectionObserver(
  scrollRef,
  ([{ isIntersecting }]) => {
    if (isIntersecting && filteredColumns.value.length > count.value) {
      count.value += 10;
    }
  },
  { threshold: 0 },
);

watch(
  () => searchInput.value,
  () => {
    if (count.value !== 20) {
      count.value = 20;
    }
  },
);

function handleDragStart(e, column) {
  draggedItem.value = column;
}

function handleDragOver(e) {
  e.preventDefault();
}

function handleDrop(e, column) {
  e.preventDefault();

  const fromIndex = reportColumns.value.findIndex((item) => item.id === draggedItem.value.id);
  const toIndex = reportColumns.value.findIndex((item) => item.id === column.id);

  if (fromIndex !== toIndex) {
    const movedItem = reportColumns.value.splice(fromIndex, 1)[0];
    reportColumns.value.splice(toIndex, 0, movedItem);
  }

  for (let i = 0; i < reportColumns.value.length; i++) {
    reportColumns.value[i].position = i + 1;
  }

  triggerRef(reportColumns);

  draggedItem.value = null;
}

function handleDragEnd() {
  draggedItem.value = null;
}

function handleToggleColumn(column, compareFn = (col) => (item) => item.id === col.id) {
  const targetIndex = reportColumns.value.findIndex(compareFn(column));

  if (targetIndex === -1) {
    return null;
  }

  const inverted = !column.enabled;

  reportColumns.value[targetIndex].enabled = inverted;

  // disable advanced reporting if an advanced column is disabled
  if (!inverted && currentReportBuilderAdvancedColumns.value[column.rawName]) {
    isAdvancedReporting.value = false;
  }

  return true;
}

function handleCreateFormulafield() {
  creatingFormula.value = true;
  editingCustomFieldId.value = undefined;
}

function handleEditCustomField(id) {
  editingCustomFieldId.value = id;
}

function handleCreateCustomfield() {
  editingCustomFieldId.value = undefined;
}

function handleModalClose() {
  creatingFormula.value = false;
  editingCustomFieldId.value = null;
}

function handleCustomfieldCreatedEvent(customfield) {
  if (handleToggleColumn(customfield, (col) => (item) => item.tempId === col.tempId)) {
    return;
  }

  const [groupIndex, category] = reportBuilderColumnCategories['Custom fields'];

  reportColumns.value.push({
    ...customfield,
    ...formatSingleColumn(customfield),
    id: customfield.id,
    type: customfield.type,
    enabled: true,
    groupIndex,
    category,
    isCustomField: true,
    icon: customfieldTypeIcons[customfield.type],
  });
}
</script>

<template>
  <div class="mb-6 flex flex-col gap-3">
    <ReportCustomBuilderOptionsColumn
      v-for="column in selectedColumns"
      :key="column.id"
      :canEdit="column.isCustomField && canEditCustomfields"
      :enabled="column.enabled"
      :column="column"
      allowDrag
      :showToggle="false"
      @dragstart="handleDragStart($event, column)"
      @dragover="handleDragOver"
      @drop="handleDrop($event, column)"
      @dragend="handleDragEnd"
      @toggleColumn="handleToggleColumn($event)"
      @edit="handleEditCustomField"
    />
    <LscMenu
      location="top start"
      origin="start start"
      contained
      :transition="{ component: VSlideXTransition }"
      :modelValue="showColumnPopover"
      @update:modelValue="showColumnPopover = $event"
    >
      <template #activator="activator">
        <LscButton
          v-if="['user', 'milestone'].includes(currentReport.type) || !canManageCustomfields"
          v-bind="activator.props"
          size="md"
          class="w-full items-center justify-center"
          prependIcon="lsi-add"
          @click="emit('handleCustomReportTrack', 'custom_reports', 'custom_report_add_columns_viewed', 'activation')"
        >
          {{ t('Add column') }}
        </LscButton>
        <WidgetOptionsMenu v-else>
          <template #activator="splitActivator">
            <LscSplitButton
              v-bind="splitActivator.props"
              size="lg"
              variant="secondary"
              leftIcon="lsi-add"
              @clickPrimary="
                (event) => {
                  activator.props.onClick(event);
                  emit('handleCustomReportTrack', 'custom_reports', 'custom_report_add_columns_viewed', 'activation');
                }
              "
            >
              {{ t('Add column') }}
            </LscSplitButton>
          </template>
          <WidgetOptionsMenuItem v-if="formulaFieldsEnabled" @click="handleCreateFormulafield">
            <div class="flex items-center gap-2">
              <span>{{ t('Create formula') }}</span>
              <LscLabel variant="highlight">{{ t('New') }}</LscLabel>
            </div>
          </WidgetOptionsMenuItem>
          <WidgetOptionsMenuItem @click="handleCreateCustomfield">
            {{ t('Create custom field') }}
          </WidgetOptionsMenuItem>
        </WidgetOptionsMenu>
      </template>
      <div
        class="ml-72 mt-16 flex max-h-[70vh] w-80 flex-1 shrink-0 flex-col rounded-md bg-default shadow-2"
        @click.stop
      >
        <div class="flex flex-col px-5 pt-3">
          <VTextField
            v-bind="VTextFieldFilter"
            v-model.trim="searchInput"
            :label="t('Search columns')"
            class="flex-1"
            autofocus
            clearable
            outlined
            @keydown.space.stop
          />
        </div>

        <div v-if="groupedReportColumns.length" class="flex flex-col gap-y-2 overflow-y-auto px-5">
          <template v-for="group in groupedReportColumns" :key="group.key">
            <div class="sticky top-0 z-10 bg-default py-2 text-body-2 font-semibold text-subtle">
              {{ group.category }}
            </div>
            <ReportCustomBuilderOptionsColumn
              v-for="column in group.items"
              :key="column.id"
              :column="column"
              :hasMaxNumberColumns="reachedMaxNumberColumns"
              :canEdit="column.isCustomField && canEditCustomfields"
              :enabled="column.enabled"
              usedInPopover
              @toggleColumn="handleToggleColumn"
              @edit="handleEditCustomField"
            />
          </template>
          <span ref="scrollRef" class="-translate-y-32" />
        </div>
        <LscEmptyState
          v-else
          size="sm"
          class="m-4 max-w-80"
          :title="t('There are no columns that match your search')"
        />
        <div v-if="canEditCustomfields" class="border-t px-4 pb-2 pt-3">
          <LscButton variant="plain-primary" size="md" prependIcon="lsi-add" @click="handleCreateCustomfield">
            {{ t('Create custom field') }}
          </LscButton>
        </div>
      </div>
    </LscMenu>
    <span v-if="showValidationError" class="m-auto text-critical">{{ validation.$errors[0].$message }}</span>
  </div>
  <ReportCustomBuilderOptionsCustomfieldDialog
    v-if="canEditCustomfields && editingCustomFieldId !== null"
    canAddFormula
    customReport
    :modelValue="true"
    :entity="currentReport.type"
    :customfieldId="editingCustomFieldId"
    :addingFormula="creatingFormula"
    @update:modelValue="handleModalClose"
    @created="handleCustomfieldCreatedEvent"
  />
</template>
