import { useCurrentAccount } from '../account/useCurrentAccount';
import { useCohort } from '../cohort/useCohort';
import { useFeatures } from '../features/useFeatures';
import { usePendo } from '../features/usePendo';

/**
 * Constants, computed properties and functions related to Experiment A-24-08
 * https://app.launchdarkly.com/default/staging/features/projects-exp-a-24-08-display-account-exec-contac-details-to-trials/targeting
 * https://digitalcrew.teamwork.com/spaces/growth/page/54865-a-24-08-display-account-exec-new-biz-rep-contact-details-to-trials
 */

export function useExperimentA2408() {
  const NORTH_AMERICA_COUNTRY_CODES = ['US', 'CA'];
  const EMEA_COUNTRY_CODES = [
    'GB',
    'IE',
    'CY',
    'HU',
    'AE',
    'PT',
    'CH',
    'GR',
    'SE',
    'NL',
    'AT',
    'BE',
    'NO',
    'LU',
    'FI',
    'DK',
    'PL',
    'ZA',
    'IT',
    'DE',
  ];

  const currentAccount = useCurrentAccount();
  const { isICP, isCompanySizeAboveTwenty, isSiteAdmin } = useCohort();
  const { displayAccountExecContacDetailsToTrialsEnabled } = useFeatures();
  const { trackExperimentInPendo } = usePendo();

  const { launchDarklyFlagKey, defaultValue } = displayAccountExecContacDetailsToTrialsEnabled;
  const ignoreInExperimentOnRuleMatch = true;

  const isSignupFromNorthAmericaCountry = computed(() =>
    NORTH_AMERICA_COUNTRY_CODES.includes(currentAccount.value?.installationSignupCountryCode),
  );
  const isSignupFromEMEACountry = computed(() =>
    EMEA_COUNTRY_CODES.includes(currentAccount.value?.installationSignupCountryCode),
  );

  const appLevelTargeting = computed(
    () =>
      isICP.value &&
      isCompanySizeAboveTwenty.value &&
      isSiteAdmin.value &&
      (currentAccount.value?.isStaging || currentAccount.value?.usedWorkEmail) &&
      (isSignupFromNorthAmericaCountry.value || isSignupFromEMEACountry.value),
  );
  const isExpA2408Variation = computed(
    () => displayAccountExecContacDetailsToTrialsEnabled.value && appLevelTargeting.value,
  );

  const productSpecialistRep = computed(() => {
    if (isSignupFromNorthAmericaCountry.value) {
      return {
        avatarImagePath:
          'https://s3.amazonaws.com/TWFiles/1/userAvatar/tf_619a24a4-58c9-4658-a9ee-ffe54c237690.avatar.jpg',
        hubspotCalendarLink: 'https://www3.teamwork.com/meetings/renee-demaree',
        userFirstName: 'Renee',
        userLastName: 'Demaree',
        role: 'product-specialist',
      };
    }

    if (isSignupFromEMEACountry.value) {
      // TODO: Remove temporary replacement for the book a call contact for EU
      // return {
      //   avatarImagePath:
      //     'https://s3.amazonaws.com/TWFiles/1/userAvatar/tf_0a501b6d-dbc6-4897-82a4-dbbf5c0d20e7.avatar.jpg',
      //   hubspotCalendarLink: 'https://www3.teamwork.com/meetings/sanjana-tirumalraju/sanjanas-calendar',
      //   userFirstName: 'Sanjana',
      //   userLastName: 'Tirumalraju',
      //   role: 'product-specialist',
      // };
      return {
        avatarImagePath:
          'https://s3.amazonaws.com/TWFiles/1/userAvatar/tf_f56d020c-ec1f-4b59-ad12-78793ea7b145.avatar.jpg',
        hubspotCalendarLink:
          'https://meetings-eu1.hubspot.com/kyle-quinn-waites/discovery-call-15-minutes?uuid=1f8133ce-843c-4859-84eb-808427c11eeb',
        userFirstName: 'Kyle',
        userLastName: 'Quinn-Waites',
        role: 'product-specialist',
      };
    }

    return null;
  });

  function trackExperimentA2408() {
    trackExperimentInPendo({
      launchDarklyFlagKey,
      defaultValue,
      ignoreInExperimentOnRuleMatch,
      appLevelTargeting,
    });
  }

  return {
    isExpA2408Variation,
    productSpecialistRep,
    trackExperimentA2408,
  };
}
