<script setup>
import { useExperimentA2413c } from '@/api';
import { sanitize, useI18n } from '@/util';
import HelpCenterDrawerTitle from '../HelpCenterDrawerTitle.vue';

const { t } = useI18n();
const activeTab = shallowRef('general');
const { isExpA2413cVariation } = useExperimentA2413c();

const shortcuts = computed(() => ({
  general: [
    {
      title: isExpA2413cVariation.value ? t('Create') : t('Quick add'),
      list: [
        {
          name: isExpA2413cVariation.value ? t('Open create menu') : t('Open quick add menu'),
          keys: ['[Q]'],
        },
        {
          name: t('Add project'),
          keys: ['[Q]', '&rarr;', '[P]'],
        },
        {
          name: t('Add milestone'),
          keys: ['[Q]', '&rarr;', '[M]'],
        },
        {
          name: t('Add task'),
          keys: ['[Q]', '&rarr;', '[T]'],
        },
        {
          name: t('Add message'),
          keys: ['[Q]', '&rarr;', '[N]'],
        },
        {
          name: t('Add event'),
          keys: ['[Q]', '&rarr;', '[E]'],
        },
        {
          name: t('Start timer'),
          keys: ['[Q]', '&rarr;', '[S]'],
        },
        {
          name: t('Log time'),
          keys: ['[Q]', '&rarr;', '[L]'],
        },
        {
          name: t('Invite users'),
          keys: ['[Q]', '&rarr;', '[U]'],
        },
      ],
    },
    {
      title: t('Within a project'),
      list: [
        {
          name: t('Open gantt chart'),
          keys: ['[G]'],
        },
        {
          name: t('Choose project section'),
          keys: ['[0-9]'],
        },
      ],
    },
    {
      title: t('Global'),
      list: [
        {
          name: t('Switch project'),
          keys: ['[S]'],
        },
        {
          name: t('Open search'),
          keys: ['[CMD]', '/', '[CTRL]', '+', '[K]'],
        },
        {
          name: t('Open Help center'),
          keys: ['[H]'],
        },
        {
          name: t('My shortcuts'),
          keys: ['[Shift]', '+', '[S]'],
        },
        {
          name: t('Personal tasks'),
          keys: ['[Shift]', '+', '[P]'],
        },
        {
          name: t('Toggle theater mode'),
          keys: ['[Shift]', '+', '[T]'],
        },
        {
          name: t('Toggle sidebar'),
          keys: ['[CMD]', '/', '[CTRL]', '+', '[B]'],
        },
      ],
    },
  ],
  tasks: [
    {
      title: t('When mouse is over a task'),
      list: [
        {
          name: t('Edit task'),
          keys: ['[E]'],
        },
        {
          name: t('Set task to me'),
          keys: ['[J]'],
        },
        {
          name: t('Set task to anyone'),
          keys: ['[A]'],
        },
        {
          name: t('Add me to task'),
          keys: ['[+]'],
        },
        {
          name: t('Remove me from task'),
          keys: ['[-]'],
        },
        {
          name: t('Complete task'),
          keys: ['[C]'],
        },
        {
          name: t('Add files to task'),
          keys: ['[F]'],
        },
        {
          name: t('Duplicate task'),
          keys: ['[D]'],
        },
        {
          name: t('Move task'),
          keys: ['[M]'],
        },
        {
          name: t('Add comment'),
          keys: ['[M]'],
        },
        {
          name: t('Log time'),
          keys: ['[T]'],
        },
        {
          name: t('Start timer'),
          keys: ['[S]'],
        },
        {
          name: t('Open quick view'),
          keys: ['[V]'],
        },
        {
          name: t('Move task to the next level down'),
          keys: ['[Tab]'],
        },
        {
          name: t('Move task to the next level up'),
          keys: ['[Shift]', '+', '[Tab]'],
        },
      ],
    },
    {
      title: t('Views'),
      list: [
        {
          name: t('Switch to board view'),
          keys: ['[B]'],
        },
        {
          name: t('Switch to list view'),
          keys: ['[L]'],
        },
      ],
    },
  ],
  'board-view': [
    {
      title: t('Navigation'),
      list: [
        {
          name: t('Navigate columns'),
          keys: ['[&larr;]', '/', '[&rarr;]'],
        },
        {
          name: t('Navigate cards'),
          keys: ['[&uarr;]', '/', '[&darr;]'],
        },
        {
          name: t('Select multiple cards'),
          keys: ['[Shift]', '+', '[&uarr;]', '/', '[&darr;]'],
        },
      ],
    },
    {
      title: t('With a column selected'),
      list: [
        {
          name: t('Edit column'),
          keys: ['[E]'],
        },
        {
          name: t('Filter cards'),
          keys: ['[F]'],
        },
      ],
    },
    {
      title: t('With a card selected'),
      list: [
        {
          name: t('Edit task'),
          keys: ['[E]'],
        },
        {
          name: t('Set task to me'),
          keys: ['[J]'],
        },
        {
          name: t('Set task to anyone'),
          keys: ['[A]'],
        },
        {
          name: t('Add me to task'),
          keys: ['[+]'],
        },
        {
          name: t('Remove me from task'),
          keys: ['[-]'],
        },
        {
          name: t('Complete task'),
          keys: ['[C]'],
        },
        {
          name: t('Duplicate task'),
          keys: ['[D]'],
        },
        {
          name: t('Add comment'),
          keys: ['[M]'],
        },
        {
          name: t('Log time'),
          keys: ['[T]'],
        },
        {
          name: t('Start timer'),
          keys: ['[S]'],
        },
        {
          name: t('Open quick view'),
          keys: ['[V]'],
        },
        {
          name: t('Move task to the next level down'),
          keys: ['[Tab]'],
        },
        {
          name: t('Move task to the next level up'),
          keys: ['[Shift]', '+', '[Tab]'],
        },
      ],
    },
  ],
}));

function isGlyph(glyph) {
  return glyph.charAt(0) === '[' && glyph.length > 1;
}
function stripGlyph(glyph) {
  return glyph.replace(/[[|\]]/g, '');
}
</script>

<template>
  <div class="flex min-h-0 shrink grow basis-0 flex-col">
    <div class="px-6">
      <h3 class="mb-6 text-subtitle-1 font-semibold">{{ t('Keyboard shortcuts') }}</h3>
      <VTabs v-bind="VTabsUnderline" v-model="activeTab">
        <VTab data-identifier="help-center-keyboard-state-tab-general" value="general">{{ t('General') }}</VTab>
        <VTab data-identifier="help-center-keyboard-state-tab-tasks" value="tasks">{{ t('Tasks') }}</VTab>
        <VTab data-identifier="help-center-keyboard-state-tab-board-view" value="board-view">
          {{ t('Board view') }}
        </VTab>
      </VTabs>
    </div>

    <div class="m-0 h-full max-h-[inherit] overflow-y-auto p-0">
      <div v-for="section in shortcuts[activeTab]" :key="section.title" class="mx-6 mt-6">
        <HelpCenterDrawerTitle :title="section.title" class="mb-4" />
        <LscSheet class="mb-6 !p-0">
          <VList class="!py-0">
            <template v-for="(shortcut, index) in section.list" :key="shortcut.title">
              <VDivider v-if="index !== 0" />
              <VListItem class="h-6">
                <VListItemTitle class="text-body-1" :title="shortcut.name">{{ shortcut.name }}</VListItemTitle>
                <template #append>
                  <div class="flex flex-row gap-2">
                    <template v-for="(glyph, charIndex) of shortcut.keys">
                      <!-- eslint-disable vue/no-v-html-->
                      <span
                        v-if="isGlyph(glyph)"
                        :key="glyph"
                        class="inline-flex h-6 min-w-7 flex-row items-center justify-center rounded-sm border border-bold bg-surface-default px-2 text-body-2 font-semibold"
                        v-html="sanitize(stripGlyph(glyph))"
                      />
                      <span
                        v-else
                        :key="`char-${charIndex}`"
                        class="inline-flex w-2 flex-row items-center justify-center text-body-1"
                        v-html="sanitize(glyph)"
                      />
                      <!-- eslint-enable vue/no-v-html-->
                    </template>
                  </div>
                </template>
              </VListItem>
            </template>
          </VList>
        </LscSheet>
      </div>
    </div>
  </div>
</template>
