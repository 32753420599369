import { useI18n } from '@/util';
import { useAxios } from '../base/useAxios';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

export function useIntegrationParagonAuthActions() {
  const { t } = useI18n();
  const api = useAxios();
  const { emit: emitRealTimeUpdate, socketId } = useRealTimeUpdates();

  return {
    /**
     * @param {string} integrationName - integration name(salesforce)
     * @returns {Promise}
     */
    ensureAuth(integrationName) {
      return api
        .get(`/synthesis/api/v2/paragon/auth/ensure.json?integrationName=${integrationName}`, {
          errorMessage: t('Failed to ensure paragon auth'),
        })
        .then((response) => {
          return response;
        });
    },

    /**
     * @param {string} integrationName - integration name(salesforce)
     * @param {boolean} enabled - true/false weather to mark as enabled or disabled
     * @returns {Promise}
     */
    setParagonIntegrationStatus(integrationName, enabled) {
      return api
        .post(
          `/synthesis/api/v2/paragon/status/${integrationName}.json`,
          { enabled },
          {
            headers: {
              'Socket-ID': socketId,
            },
            errorMessage: t('There was an issue updating paragon integration status.'),
          },
        )
        .then(() => {
          emitRealTimeUpdate({
            type: 'integration',
            action: `paragon-${enabled ? 'enabled' : 'disabled'}`,
          });
        });
    },

    /**
     * @param {string} integrationName - integration name(salesforce)
     * @returns {Promise}
     */
    updateAPIKey(integrationName) {
      return api
        .patch(
          `/synthesis/api/v2/paragon/auth/apikey.json`,
          { integrationName },
          {
            errorMessage: t('Failed to update {paragon} API key', { paragon: 'Paragon' }),
          },
        )
        .then((response) => {
          return response;
        });
    },
  };
}
