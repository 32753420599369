<script setup>
import { refDebounced, syncRef } from '@vueuse/core';
import { usePeopleV3Loader } from '@/api';
import { useI18n, useItemCache } from '@/util';
import { normalizeFilterIds } from './normalize';
import { useFilter, useFilterChips, useFilterClear, useFilterCount, useFilterNormalize } from './useFilter';

const props = defineProps({
  projectId: {
    type: Number,
    default: undefined,
  },
  name: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  defaultValue: {
    type: String,
    default: '',
  },
  dataIdentifier: {
    type: String,
    default: '',
  },
  loaderParams: {
    type: Object,
    default: () => ({}),
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});
const { t } = useI18n();
const { params, activeFilter, dataIdentifierPrefix } = useFilter();
const searchTerm = shallowRef('');
const debouncedSearchTerm = refDebounced(searchTerm, 300);
const pageSize = 10;
const count = shallowRef(-1);
const filterType = 'users';
const peopleParams = {
  type: 'all',
  includeUsersWithoutProjects: false,
  includeObservers: false,
  ...props.loaderParams,
};

const state = usePeopleV3Loader({
  projectId: computed(() => props.projectId),
  params: computed(() => ({ ...peopleParams, searchTerm: debouncedSearchTerm.value })),
  count,
  pageSize,
});
const { items: people } = state;

const missingPeopleIds = shallowRef([]);
const missingPeopleState = usePeopleV3Loader({
  params: computed(() => ({
    ...peopleParams,
    ids: missingPeopleIds.value.join(','),
  })),
  count: computed(() => missingPeopleIds.value.length || -1),
  pageSize: 50,
});

const { computeAll, computeMissing } = useItemCache(
  computed(() => Object.values(activeFilter.value?.included?.[filterType] || {})),
  state.items,
  missingPeopleState.items,
);

const assigneeIds = computed({
  get() {
    return typeof params.value[props.name] === 'string'
      ? params.value[props.name].split(',').map(Number).filter(Boolean)
      : [];
  },
  set(value) {
    params.value = {
      ...params.value,
      [props.name]: value.join(','),
    };
  },
});

const assignees = computeAll(assigneeIds, (id) => ({ id, name: t('Unknown') }));

syncRef(computeMissing(assigneeIds), missingPeopleIds, { direction: 'ltr' });

useFilterNormalize(toRef(props, 'name'), (value) => normalizeFilterIds(value, props.defaultValue));

useFilterClear(toRef(props, 'name'), toRef(props, 'defaultValue'));

useFilterChips(
  computed(() =>
    assignees.value.map((assignee) => ({
      // color, name, etc
      name: assignee.firstName && assignee.lastName ? `${assignee.firstName} ${assignee.lastName}` : assignee.name,
      icon: assignee.avatarUrl,
      type: 'assignee',
      delete: () => {
        assigneeIds.value = assigneeIds.value.filter((id) => id !== assignee.id);
      },
    })),
  ),
);

useFilterCount(computed(() => assigneeIds.value.length));

function togglePerson({ id }) {
  if (assigneeIds.value.includes(id)) {
    assigneeIds.value = assigneeIds.value.filter((assigneeId) => assigneeId !== id);
  } else {
    assigneeIds.value = [...assigneeIds.value, id];
  }
}

function icon(id) {
  return assigneeIds.value.includes(id) ? 'lsi-remove' : 'lsi-add';
}

function toggleMenu(opened) {
  if (!opened || count.value >= 0) {
    return;
  }
  count.value = pageSize;
}
</script>
<template>
  <LscMenu :closeOnContentClick="false" location="bottom left" offset="12" @update:modelValue="toggleMenu">
    <template #activator="activator">
      <LswFilterOptionButton
        v-bind="activator.props"
        :ariaExpanded="activator.isActive"
        :ariaPressed="assignees.length > 0"
        icon="lsi-user"
        :data-identifier="`${dataIdentifierPrefix}-${dataIdentifier || name}`"
        :disabled="disabled"
      >
        {{ title }}
      </LswFilterOptionButton>
    </template>
    <LscSheet class="flex w-72 flex-col px-0">
      <LscSearchBar v-model="searchTerm" :clearable="true" :placeholder="t('Search people')" autofocus class="mx-4" />
      <WidgetLoadingState :state="state" :blankTitle="t('No assignees found')">
        <template #default>
          <VList density="compact" maxHeight="384">
            <VListItem
              v-for="person in people"
              :key="person.id"
              :active="assigneeIds.includes(person.id)"
              :data-identifier="`${dataIdentifierPrefix}-people-item`"
              @click="togglePerson(person)"
            >
              <div class="flex items-center gap-2">
                <LscAvatar v-bind="getLsAvatarProps({ user: person })" />
                <LscOverflowEllipsis class="flex-1 text-body-1">
                  {{ person.firstName }} {{ person.lastName }}
                </LscOverflowEllipsis>
                <LscIcon :icon="icon(person.id)" class="text-icon-subtle" size="sm" />
              </div>
            </VListItem>
            <WidgetLoadingLazy v-model:count="count" :state="state" :step="pageSize" />
          </VList>
        </template>
        <!-- Return empty blank state as per design -->
        <template #blank>
          <div class="mb-2" />
        </template>
      </WidgetLoadingState>
    </LscSheet>
  </LscMenu>
</template>
