<script setup>
import { refDebounced } from '@vueuse/core';
import { usePeopleV3Loader } from '@/api';
import { useI18n } from '@/util';

const emit = defineEmits(['onClick']);
const { t } = useI18n();

const searchTerm = shallowRef('');
const pageSize = 10;
const count = shallowRef(-1);
const debouncedSearchTerm = refDebounced(searchTerm, 300);

const state = usePeopleV3Loader({
  params: computed(() => ({
    type: 'all',
    includeUsersWithoutProjects: false,
    includeObservers: false,
    searchTerm: debouncedSearchTerm.value,
  })),
  count,
  pageSize,
});

const { items: people } = state;

function toggleMenu(opened) {
  if (!opened || count.value >= 0) {
    return;
  }
  count.value = pageSize;
}
</script>
<template>
  <LscMenu :closeOnContentClick="false" location="bottom left" offset="12" @update:modelValue="toggleMenu">
    <template #activator="activator">
      <LswFilterOptionButton
        v-bind="activator.props"
        :ariaExpanded="activator.isActive"
        icon="lsi-user"
        data-identifier="share-filter-users-btn"
        :disabled="false"
        class="mt-2 w-full"
      >
        {{ t('Search for users') }}
      </LswFilterOptionButton>
    </template>
    <LscSheet v-bind="VCardRounded" class="w-72">
      <div class="pl-2 pr-2">
        <VTextField
          v-bind="VTextFieldFilter"
          v-model.trim="searchTerm"
          class="mt-2 !min-w-full"
          :placeholder="t('Search people')"
          prependInnerIcon="lsi-search"
          :active="Boolean(searchTerm)"
          autofocus
        />
      </div>
      <WidgetLoadingState :state="state" :blankTitle="t('No assignees found')">
        <template #default>
          <VList density="compact" maxHeight="384">
            <VListItem
              v-for="person in people"
              :key="person.id"
              data-identifier="share-filter-users-list"
              @click="emit('onClick', person)"
            >
              <div class="flex items-center gap-2">
                <LscAvatar v-bind="getLsAvatarProps({ user: person })" />
                <span class="flex-1 truncate text-body-1">{{ person.firstName }} {{ person.lastName }}</span>
              </div>
            </VListItem>
          </VList>
          <WidgetLoadingLazy v-model:count="count" :state="state" :step="pageSize" />
        </template>
        <!-- Return empty blank state as per design -->
        <template #blank>
          <div class="mb-2" />
        </template>
      </WidgetLoadingState>
    </LscSheet>
  </LscMenu>
</template>
