<script setup>
import { useI18n } from '@/util';
import ReportTableFooterCell from '../../table/ReportTableFooterCell.vue';
import ReportTableFooterOptions from '../../table/ReportTableFooterOptions.vue';

const props = defineProps({
  item: {
    type: Object,
    default: () => ({}),
  },
});

const { formatCurrency, formatMinutes, formatPercentage, t } = useI18n();

const summaryOptionMode = shallowRef('total');

const summaryRowConfiguration = computed(() => ({
  rowsCount: {
    options: [
      {
        key: 'rowsCount',
        label: t('Total'),
        value: props.item.rowsCount?.total,
      },
    ],
  },
  activeProjects: {
    options: [
      {
        key: 'activeProjectsAvg',
        label: t('Average'),
        value: props.item.activeProjects?.average,
      },
    ],
  },
  actualUtilization: {
    options: [
      {
        key: 'actualUtilization',
        label: t('Total'),
        value: props.item.actualUtilization?.total
          ? formatPercentage(props.item.actualUtilization.total / 100)
          : formatPercentage(0),
        default: true,
      },
      {
        key: 'actualUtilizationAvg',
        label: t('Average'),
        value: props.item.actualUtilization?.average
          ? formatPercentage(props.item.actualUtilization.average / 100)
          : formatPercentage(0),
      },
    ],
  },
  allocatedTime: {
    options: [
      {
        key: 'allocatedTime',
        label: t('Total'),
        value: formatMinutes(props.item.allocatedTime?.total),
        default: true,
      },
      {
        key: 'allocatedTimeAvg',
        label: t('Average'),
        value: formatMinutes(props.item.allocatedTime?.average),
      },
    ],
  },
  allocatedTimePercentage: {
    options: [
      {
        key: 'allocatedTimePercentage',
        label: t('Average'),
        value: formatMinutes(props.item.allocatedTimePercentage?.average),
        default: true,
      },
    ],
  },
  assignedTasks: {
    options: [
      {
        key: 'assignedTasksAvg',
        label: t('Average'),
        value: props.item.assignedTasks?.average,
      },
    ],
  },
  availableTime: {
    options: [
      {
        key: 'availableTime',
        label: t('Total'),
        value: formatMinutes(props.item.availableTime?.total),
        default: true,
      },
      {
        key: 'availableTimeAvg',
        label: t('Average'),
        value: formatMinutes(props.item.availableTime?.average),
      },
    ],
  },
  billableTime: {
    options: [
      {
        key: 'billableTime',
        label: t('Total'),
        value: formatMinutes(props.item.billableTime?.total),
        default: true,
      },
      {
        key: 'billableTimeAvg',
        label: t('Average'),
        value: formatMinutes(props.item.billableTime?.average),
      },
    ],
  },

  billableTotal: {
    options: [
      {
        key: 'billableTotal',
        label: t('Total'),
        value: formatCurrency(props.item.billableTotal?.total),
        default: true,
      },
      {
        key: 'billableTotalAvg',
        label: t('Average'),
        value: formatCurrency(props.item.billableTotal?.average),
      },
    ],
  },
  billableUtilization: {
    options: [
      {
        key: 'billableUtilizationAvg',
        label: t('Average'),
        value: props.item.billableUtilization?.average
          ? formatPercentage(props.item.billableUtilization.average / 100)
          : formatPercentage(0),
      },
    ],
  },
  completedTasks: {
    options: [
      {
        key: 'completedTasksAvg',
        label: t('Average'),
        value: props.item.completedTasks?.average,
      },
    ],
  },
  estimatedTime: {
    options: [
      {
        key: 'estimatedTime',
        label: t('Total'),
        value: formatMinutes(props.item.estimatedTime?.total),
        default: true,
      },
      {
        key: 'estimatedTimeAvg',
        label: t('Average'),
        value: formatMinutes(props.item.estimatedTime?.average),
      },
    ],
  },
  estimatedUtilization: {
    options: [
      {
        key: 'estimatedUtilizationAvg',
        label: t('Average'),
        value: props.item.estimatedUtilization?.average
          ? formatPercentage(props.item.estimatedUtilization.average / 100)
          : formatPercentage(0),
      },
    ],
  },
  forecastProfitNumber: {
    options: [
      {
        key: 'forecastProfitNumber',
        label: t('Total'),
        value: formatCurrency(props.item.forecastProfitNumber?.total),
      },
      {
        key: 'forecastProfitNumberAvg',
        label: t('Average'),
        value: formatCurrency(props.item.forecastProfitNumber?.average),
      },
    ],
  },
  forecastProfitPercentage: {
    options: [
      {
        key: 'forecastProfitPercent',
        label: t('Average'),
        value: props.item.forecastProfitPercent?.average
          ? formatPercentage(props.item.forecastProfitPercent.average / 100)
          : formatPercentage(0),
      },
    ],
  },
  forecastBillableTotal: {
    options: [
      {
        key: 'forecastBillableTotal',
        label: t('Total'),
        value: formatCurrency(props.item.forecastBillableTotal?.total),
        default: true,
      },
      {
        key: 'forecastBillableTotalAverage',
        label: t('Average'),
        value: formatMinutes(props.item.forecastBillableTotal?.average),
      },
    ],
  },
  forecastBillableUtilization: {
    options: [
      {
        key: 'forecastBillableUtilizationAvg',
        label: t('Average'),
        value: formatMinutes(props.item.forecastBillableUtilization?.average),
      },
    ],
  },
  forecastCost: {
    options: [
      {
        key: 'forecastcost',
        label: t('Total'),
        value: formatCurrency(props.item.forecastCost?.total),
        default: true,
      },
      {
        key: 'forecastcostAvg',
        label: t('Average'),
        value: formatCurrency(props.item.forecastCost?.average),
      },
    ],
  },
  loggedTime: {
    options: [
      {
        key: 'loggedTime',
        label: t('Total'),
        value: formatMinutes(props.item.loggedTime?.total),
        default: true,
      },
      {
        key: 'loggedTimeAvg',
        label: t('Average'),
        value: formatMinutes(props.item.loggedTime?.average),
      },
    ],
  },
  loggedTimeVsEstimatedTime: {
    options: [
      {
        key: 'loggedTimeVsEstimatedTime',
        label: t('Total'),
        value: formatMinutes(props.item.loggedTimeVsEstimatedTime?.total),
        default: true,
      },
      {
        key: 'loggedTimeVsEstimatedTime',
        label: t('Average'),
        value: formatMinutes(props.item.loggedTimeVsEstimatedTime?.average),
      },
    ],
  },
  nonBillableTime: {
    options: [
      {
        key: 'nonBillableTime',
        label: t('Total'),
        value: formatMinutes(props.item.nonBillableTime?.total),
        default: true,
      },
      {
        key: 'nonBillableTimeAvg',
        label: t('Average'),
        value: formatMinutes(props.item.nonBillableTime?.average),
      },
    ],
  },
  nonBillableUtilization: {
    options: [
      {
        key: 'nonBillableUtilization',
        label: t('Total'),
        value: props.item.nonBillableUtilization?.total
          ? formatPercentage(props.item.nonBillableUtilization.total / 100)
          : formatPercentage(0),
        default: true,
      },
      {
        key: 'nonBillableUtilizationAvg',
        label: t('Average'),
        value: props.item.nonBillableUtilization?.average
          ? formatPercentage(props.item.nonBillableUtilization.average / 100)
          : formatPercentage(0),
      },
    ],
  },
  overdueTasks: {
    options: [
      {
        key: 'overdueTasks',
        label: t('Total'),
        value: props.item.overdueTasks?.total,
      },
      {
        key: 'overdueTasksAvg',
        label: t('Average'),
        value: props.item.overdueTasks?.average,
      },
    ],
  },
  profitNumber: {
    options: [
      {
        key: 'profitNumber',
        label: t('Total'),
        value: formatCurrency(props.item.profitNumber?.total),
        default: true,
      },
      {
        key: 'profitNumberAvg',
        label: t('Average'),
        value: formatCurrency(props.item.profitNumber?.average),
      },
    ],
  },
  profitPercentage: {
    options: [
      {
        key: 'profitPercentage',
        label: t('Average'),
        value: props.item.profitPercentage?.average
          ? formatPercentage(props.item.profitPercentage.average / 100)
          : formatPercentage(0),
      },
    ],
  },
  taskTotal: {
    options: [
      {
        key: 'taskTotal',
        label: t('Total'),
        value: props.item.taskTotal?.total,
        default: true,
      },
      {
        key: 'taskTotalAvg',
        label: t('Average'),
        value: props.item.taskTotal?.average,
      },
    ],
  },
  timeEstimate: {
    options: [
      {
        key: 'timeEstimate',
        label: t('Total'),
        value: formatMinutes(props.item.timeEstimate?.total),
        default: true,
      },
      {
        key: 'timeEstimateAvg',
        label: t('Average'),
        value: formatMinutes(props.item.timeEstimate?.average),
      },
    ],
  },
  timeLogged: {
    options: [
      {
        key: 'timeLogged',
        label: t('Total'),
        value: formatMinutes(props.item.timeLogged?.total),
        default: true,
      },
      {
        key: 'timeLoggedAvg',
        label: t('Average'),
        value: formatMinutes(props.item.timeLogged?.average),
      },
    ],
  },
  totalCost: {
    options: [
      {
        key: 'totalCost',
        label: t('Total'),
        value: formatCurrency(props.item.totalCost?.total),
        default: true,
      },
      {
        key: 'totalCostAvg',
        label: t('Average'),
        value: formatCurrency(props.item.totalCost?.average),
      },
    ],
  },
  unavailableTime: {
    options: [
      {
        key: 'unavailableTime',
        label: t('Total'),
        value: formatMinutes(props.item.unavailableTime?.total),
        default: true,
      },
      {
        key: 'unavailableTimeAvg',
        label: t('Average'),
        value: formatMinutes(props.item.unavailableTime?.average),
      },
    ],
  },
  utilizationPercentTarget: {
    options: [
      {
        key: 'utilizationPercentTarget',
        label: t('Average'),
        value: formatPercentage(props.item.utilizationPercentTarget?.average),
      },
    ],
  },
}));

const showTotalRow = computed(() => summaryRowConfiguration.value.rowsCount.options[0].value);

function handleUpdateSummaryMode(mode) {
  summaryOptionMode.value = mode;
}
</script>

<template>
  <LscTableFooter v-if="showTotalRow">
    <template #projectName>
      <ReportTableFooterCell :config="summaryRowConfiguration['rowsCount']" />
    </template>
    <template #milestoneName>
      <ReportTableFooterCell :config="summaryRowConfiguration['rowsCount']" />
    </template>
    <template #userName>
      <ReportTableFooterCell :config="summaryRowConfiguration['rowsCount']" />
    </template>
    <template #taskName>
      <ReportTableFooterCell :config="summaryRowConfiguration['rowsCount']" />
    </template>
    <template #activeprojects>
      <ReportTableFooterCell :config="summaryRowConfiguration['activeProjects']" :mode="summaryOptionMode" />
    </template>
    <template #actualutilization>
      <ReportTableFooterCell :config="summaryRowConfiguration['actualUtilization']" :mode="summaryOptionMode" />
    </template>
    <template #assignedtasks>
      <ReportTableFooterCell :config="summaryRowConfiguration['assignedTasks']" :mode="summaryOptionMode" />
    </template>
    <template #availabletime>
      <ReportTableFooterCell :config="summaryRowConfiguration['availableTime']" :mode="summaryOptionMode" />
    </template>
    <template #billabletime>
      <ReportTableFooterCell :config="summaryRowConfiguration['billableTime']" :mode="summaryOptionMode" />
    </template>
    <template #billabletotal>
      <ReportTableFooterCell :config="summaryRowConfiguration['billableTotal']" :mode="summaryOptionMode" />
    </template>
    <template #completedtasks>
      <ReportTableFooterCell :config="summaryRowConfiguration['completedTasks']" :mode="summaryOptionMode" />
    </template>
    <template #difference>
      <!-- TODO: add -->
    </template>
    <template #estimatedtime>
      <ReportTableFooterCell :config="summaryRowConfiguration['estimatedTime']" :mode="summaryOptionMode" />
    </template>
    <template #estimatedutilization>
      <ReportTableFooterCell :config="summaryRowConfiguration['estimatedUtilization']" :mode="summaryOptionMode" />
    </template>
    <template #forecastprofitnumber>
      <ReportTableFooterCell :config="summaryRowConfiguration['forecastProfitNumber']" :mode="summaryOptionMode" />
    </template>
    <template #forecastbillabletotal>
      <ReportTableFooterCell :config="summaryRowConfiguration['forecastBillableTotal']" :mode="summaryOptionMode" />
    </template>
    <template #forecastbillableutilization>
      <ReportTableFooterCell
        :config="summaryRowConfiguration['forecastBillableUtilization']"
        :mode="summaryOptionMode"
      />
    </template>
    <template #forecastcost>
      <ReportTableFooterCell :config="summaryRowConfiguration['forecastCost']" :mode="summaryOptionMode" />
    </template>
    <template #loggedtime>
      <ReportTableFooterCell :config="summaryRowConfiguration['loggedTime']" :mode="summaryOptionMode" />
    </template>
    <template #loggedtimevsestimatedtime>
      <ReportTableFooterCell :config="summaryRowConfiguration['loggedTimeVsEstimatedTime']" :mode="summaryOptionMode" />
    </template>
    <template #nonbillabletime>
      <ReportTableFooterCell :config="summaryRowConfiguration['nonBillableTime']" :mode="summaryOptionMode" />
    </template>
    <template #billableutilization>
      <ReportTableFooterCell :config="summaryRowConfiguration['billableUtilization']" :mode="summaryOptionMode" />
    </template>
    <template #nonbillableutilization>
      <ReportTableFooterCell :config="summaryRowConfiguration['nonBillableUtilization']" :mode="summaryOptionMode" />
    </template>
    <template #overduetasks>
      <ReportTableFooterCell :config="summaryRowConfiguration['overdueTasks']" :mode="summaryOptionMode" />
    </template>
    <template #profitnumber>
      <ReportTableFooterCell :config="summaryRowConfiguration['profitNumber']" :mode="summaryOptionMode" />
    </template>
    <template #progress>
      <!-- TODO: add -->
    </template>
    <template #percentagetaskscompleted>
      <!-- TODO: add -->
    </template>
    <template #tasktotal>
      <ReportTableFooterCell :config="summaryRowConfiguration['taskTotal']" :mode="summaryOptionMode" />
    </template>
    <template #timeestimate>
      <ReportTableFooterCell :config="summaryRowConfiguration['timeEstimate']" :mode="summaryOptionMode" />
    </template>
    <template #timelogged>
      <ReportTableFooterCell :config="summaryRowConfiguration['timeLogged']" :mode="summaryOptionMode" />
    </template>
    <template #totalcost>
      <ReportTableFooterCell :config="summaryRowConfiguration['totalCost']" :mode="summaryOptionMode" />
    </template>
    <template #unavailabletime>
      <ReportTableFooterCell :config="summaryRowConfiguration['unavailableTime']" :mode="summaryOptionMode" />
    </template>
    <template #percentage="{ column }">
      <ReportTableFooterCell :config="summaryRowConfiguration[column.itemKey]" :mode="summaryOptionMode" />
    </template>
    <template #time="{ column }">
      <ReportTableFooterCell :config="summaryRowConfiguration[column.itemKey]" :mode="summaryOptionMode" />
    </template>
    <template #integer="{ column }">
      <ReportTableFooterCell :config="summaryRowConfiguration[column.itemKey]" :mode="summaryOptionMode" />
    </template>
    <template #more>
      <ReportTableFooterOptions @update="handleUpdateSummaryMode" />
    </template>
  </LscTableFooter>
</template>
