<script setup>
import { useI18n } from '@/util';
import { LscButtonTypes, LscSplitButtonSizes, LscSplitButtonVariants } from './constants';

defineOptions({
  inheritAttrs: false,
});

const props = defineProps({
  active: {
    type: Boolean,
    default: false,
  },
  activeDropdown: {
    type: Boolean,
    default: false,
  },
  dataIdentifierPrefix: {
    type: String,
    default: undefined,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  /**
   * The icon to display on the left side of the button.
   * @type {PropType<LscIcon>}
   */
  leftIcon: {
    type: String,
    default: undefined,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  /**
   * The icon to display on the right side of the button.
   * @type {PropType<LscIcon>}
   */
  rightIcon: {
    type: String,
    default: 'lsi-dropdown',
  },
  rightLabel: {
    type: String,
    default: undefined,
  },
  /**
   * The type of the left button. Valid values are `button`, `submit`, and `reset`.
   * @type {PropType<typeof LscButtonTypes[number]>}
   */
  type: {
    type: String,
    default: 'button',
    validator: (value) => LscButtonTypes.includes(value),
  },
  /**
   * The size of the button.
   * @type {PropType<typeof LscSplitButtonSizes[number]>}
   */
  size: {
    type: String,
    default: 'md',
    validator: (value) => LscSplitButtonSizes.includes(value),
  },
  /**
   * The variant of the button.
   * @type {PropType<typeof LscSplitButtonVariants[number]>}
   */
  variant: {
    type: String,
    default: 'primary',
    validator: (value) => LscSplitButtonVariants.includes(value),
  },
});

const emit = defineEmits(['clickPrimary', 'click']);
const { t } = useI18n();

const rightLabel = computed(() => props.rightLabel || t('More options'));

const iconSizes = {
  sm: 'xs',
  md: 'sm',
  lg: 'md',
};

const iconSize = computed(() => iconSizes[props.size]);

const splitButtonVariantStyleConfig = tv({
  base: ['relative inline-flex items-center rounded-full font-semibold'],
  slots: {
    buttonLeft: ['flex-1 justify-center rounded-l-full'],
    buttonRight: ['rounded-r-full', 'after:absolute after:left-0 after:w-px', 'disabled:after:!invisible'],
    icon: [],
  },
  variants: {
    size: {
      sm: {
        buttonLeft: ['pl-2 pr-2'],
        buttonRight: ['pl-2 pr-2', 'after:h-3'],
      },
      md: {
        buttonLeft: ['pl-3 pr-2'],
        buttonRight: ['pl-2 pr-3', 'after:h-4'],
      },
      lg: {
        buttonLeft: ['pl-4 pr-2'],
        buttonRight: ['pl-2 pr-4', 'after:h-4'],
        icon: ['!size-[--lsds-a-icon-size-lg]', '!text-[length:--lsds-a-icon-size-lg]'],
      },
    },
    variant: {
      primary: {
        buttonRight: ['after:bg-[--lsds-c-button-color-divider-primary]'],
      },
      secondary: {
        base: [
          'border border-[color:--lsds-c-button-color-border-secondary-default]',
          'disabled:border-[color:--lsds-c-button-color-bg-secondary-disabled]',
        ],
        buttonRight: ['after:bg-[--lsds-c-button-color-divider-secondary]'],
      },
      tertiary: {
        buttonRight: ['after:bg-[--lsds-c-button-color-divider-tertiary]'],
      },
    },
  },
  compoundSlots: [
    {
      slots: ['buttonLeft', 'buttonRight'],
      class: [
        'relative inline-flex content-center items-center font-semibold outline-none',
        'focus-within:focus-visible:ring-[length:--lsds-c-button-focus-ring-width]',
        'focus-visible:z-10',
      ],
    },
    {
      size: 'sm',
      slots: ['buttonLeft', 'buttonRight'],
      class: [
        'text-button-2',
        'h-[--lsds-c-button-size-sm]',
        'px-[--lsds-c-button-spacing-outer-sm]',
        'gap-[--lsds-c-button-spacing-gap-sm]',
      ],
    },
    {
      size: 'md',
      slots: ['buttonLeft', 'buttonRight'],
      class: [
        'text-button-1',
        'h-[--lsds-c-button-size-md]',
        'px-[--lsds-c-button-spacing-outer-md]',
        'gap-[--lsds-c-button-spacing-gap-md]',
      ],
    },
    {
      size: 'lg',
      slots: ['buttonLeft', 'buttonRight'],
      class: [
        'text-button-1',
        'h-[--lsds-c-button-size-lg]',
        'px-[--lsds-c-button-spacing-outer-lg]',
        'gap-[--lsds-c-button-spacing-gap-lg]',
      ],
    },
    {
      variant: 'primary',
      slots: ['buttonLeft', 'buttonRight'],
      disabled: false,
      class: [
        'bg-[--lsds-c-button-color-bg-primary-default]',
        'text-[color:--lsds-c-button-color-text-primary-default]',
        'ring-[color:--lsds-c-button-color-focus-ring-primary]',
        'hover:bg-[--lsds-c-button-color-bg-primary-hover]',
        'aria-pressed:bg-[--lsds-c-button-color-bg-primary-active]',
        'focus:bg-[--lsds-c-button-color-bg-primary-active]',
        'active:bg-[--lsds-c-button-color-bg-primary-active]',
      ],
    },
    {
      variant: 'primary',
      slots: ['buttonLeft', 'buttonRight'],
      disabled: true,
      class: [
        'bg-[--lsds-c-button-color-bg-primary-disabled]',
        'text-[color:--lsds-c-button-color-text-primary-disabled]',
      ],
    },
    {
      variant: 'secondary',
      slots: ['buttonLeft', 'buttonRight'],
      disabled: false,
      class: [
        'bg-[--lsds-c-button-color-bg-secondary-default]',
        'text-[color:--lsds-c-button-color-text-secondary-default]',
        'ring-[color:--lsds-c-button-color-focus-ring-secondary]',
        'hover:bg-[--lsds-c-button-color-bg-secondary-hover]',
        'hover:border-[color:--lsds-c-button-color-border-secondary-hover]',
        'aria-pressed:bg-[--lsds-c-button-color-bg-secondary-active]',
        'aria-pressed:border-[color:--lsds-c-button-color-border-secondary-active]',
        'active:bg-[--lsds-c-button-color-bg-secondary-active]',
        'active:border-[color:--lsds-c-button-color-border-secondary-active]',
        'focus-visible:border-[color:--lsds-c-button-color-border-secondary-focus]',
      ],
    },
    {
      variant: 'secondary',
      slots: ['buttonLeft', 'buttonRight'],
      disabled: true,
      class: [
        'bg-[--lsds-c-button-color-bg-secondary-disabled]',
        'border-[color:--lsds-c-button-color-bg-secondary-disabled]',
        'text-[color:--lsds-c-button-color-text-secondary-disabled]',
      ],
    },
    {
      variant: 'tertiary',
      slots: ['buttonLeft', 'buttonRight'],
      disabled: false,
      class: [
        'bg-[--lsds-c-button-color-bg-tertiary-default]',
        'text-[color:--lsds-c-button-color-text-tertiary-default]',
        'ring-[color:--lsds-c-button-color-focus-ring-tertiary]',
        'hover:bg-[--lsds-c-button-color-bg-tertiary-hover]',
        'aria-pressed:bg-[--lsds-c-button-color-bg-tertiary-active]',
        'active:bg-[--lsds-c-button-color-bg-tertiary-active]',
      ],
    },
    {
      variant: 'tertiary',
      slots: ['buttonLeft', 'buttonRight'],
      disabled: true,
      class: [
        'bg-[--lsds-c-button-color-bg-tertiary-disabled]',
        'text-[color:--lsds-c-button-color-text-tertiary-disabled]',
      ],
    },
  ],
});

const classes = computed(() =>
  splitButtonVariantStyleConfig({
    size: props.size,
    variant: props.variant,
    disabled: props.disabled,
  }),
);
</script>
<template>
  <div :class="classes.base()" :aria-disabled="disabled" role="group">
    <button
      :disabled="disabled || loading"
      :aria-pressed="active"
      :class="classes.buttonLeft()"
      :data-identifier="dataIdentifierPrefix ? `${dataIdentifierPrefix}-action` : undefined"
      :type="type"
      @click="emit('clickPrimary', $event)"
    >
      <div :class="{ invisible: loading }" class="contents">
        <template v-if="leftIcon">
          <LscIcon :icon="leftIcon" :size="iconSize" :class="classes.icon()" />
        </template>
        <!-- @slot Default button text -->
        <slot />
      </div>

      <div v-if="loading" class="absolute inset-0 flex items-center justify-center">
        <VProgressCircular size="16" indeterminate :width="2" :class="classes.icon()" />
      </div>
    </button>
    <button
      type="button"
      :disabled="disabled || loading"
      :aria-pressed="activeDropdown"
      :aria-label="rightLabel"
      :class="classes.buttonRight()"
      :data-identifier="dataIdentifierPrefix ? `${dataIdentifierPrefix}-dropdown` : undefined"
      @click="emit('click', $event)"
    >
      <LscIcon :icon="rightIcon" :size="iconSize" :class="classes.icon()" />
    </button>
  </div>
</template>
