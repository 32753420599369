<script setup>
import { useCurrentAccount } from '@/api';
import { useI18n } from '@/util';
import {
  Filter,
  FilterCheckbox,
  FilterCompanyPicker,
  FilterPeoplePicker,
  FilterProjectPicker,
  FilterStatusTypes,
  FilterTeamPicker,
} from '@/module/filter';
import { useReportCustomBuilderFilters } from '../useReportCustomBuilderFilters';

const { t } = useI18n();

const { filterParams } = useReportCustomBuilderFilters();
const account = useCurrentAccount();
const clientOrCompanyText = computed(() => (account.value?.useClientView ? t('Client') : t('Company')));
</script>

<template>
  <Filter
    v-model="filterParams"
    dataIdentifierPrefix="report-builder-filter"
    section="reportBuilderFilter"
    class="flex flex-col"
    :allowSaveFilters="false"
  >
    <template #menuFilters>
      <FilterPeoplePicker :title="t('Assignee')" name="assigneeUserIds" />
      <FilterCompanyPicker :title="clientOrCompanyText" name="companyIds" />
      <FilterTeamPicker
        :title="t('Team')"
        name="teamIds"
        :loaderParams="{
          includeCompanyTeams: true,
          includeSubteams: true,
          includeProjectTeams: true,
        }"
      />
      <FilterProjectPicker :title="t('Project')" name="projectIds" />
      <FilterStatusTypes :title="t('Milestone status')" name="status" />
    </template>
    <template #menuOptions>
      <FilterCheckbox name="includeCompletedMilestones" :label="t('Include completed milestones')" />
      <FilterCheckbox name="includeArchivedProjects" :label="t('Include data from archived projects')" />
    </template>
  </Filter>
</template>
