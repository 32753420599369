<script setup>
import { useFeatures } from '@/api';
import { useCurrentBreadcrumbs } from '@/route';
import { FeedbackButton, useFeedbackDataIdentifier } from '@/module/feedback';
import { LegacyViewSwitch } from '@/module/legacy';
import { TourButton, useTourDataIdentifier } from '@/module/onboarding';
import { useDarkMode } from '../darkMode/useDarkMode';

const { isDark, toggleDark } = useDarkMode();
const { darkModeEnabled } = useFeatures();
const breadcrumbs = useCurrentBreadcrumbs();
const feedbackDataIdentifier = useFeedbackDataIdentifier();
const { tourDataIdentifier, tourFeatureName } = useTourDataIdentifier();
</script>

<template>
  <div class="flex h-8 min-w-0 items-center justify-between gap-4 border-b border-b-subtle bg-default px-5">
    <div class="flex items-center gap-1">
      <LscIcon icon="lsi-home" size="xs" class="shrink-0 text-subtle" />
      <LscBreadcrumbs size="sm" class="flex-1" :items="breadcrumbs" :expandedOverflow="false" />
    </div>
    <div class="flex shrink-0 items-center gap-3">
      <div v-if="darkModeEnabled" class="relative mr-2 inline-flex items-center">
        <LscButton variant="secondary" size="sm" @click="toggleDark">
          {{ isDark ? '☀️' : '🌙' }}
        </LscButton>
      </div>
      <TourButton
        v-if="tourDataIdentifier"
        :data-identifier="tourDataIdentifier"
        :featureName="tourFeatureName"
        size="sm"
      />
      <FeedbackButton v-if="feedbackDataIdentifier" :data-identifier="feedbackDataIdentifier" size="sm" />
      <LegacyViewSwitch class="ml-auto" />
    </div>
  </div>
</template>
