<script setup>
import { LscEmptyStateSizes, LscEmptyStateVariants } from './constants';

const props = defineProps({
  /**
   * The variant of the empty state.
   * @type {PropType<LscEmptyStateVariants>}
   */
  variant: {
    type: String,
    default: 'default',
    validator: (v) => LscEmptyStateVariants.includes(v),
  },
  /**
   * The size of the empty state.
   * @type {PropType<LscEmptyStateSizes>}
   */
  size: {
    type: String,
    default: 'md',
    validator: (v) => LscEmptyStateSizes.includes(v),
  },
  /**
   * The empty state title.
   */
  title: {
    type: String,
    default: undefined,
  },
  /**
   * The empty state message.
   */
  message: {
    type: String,
    default: undefined,
  },
  /**
   * Whether should show the image.
   */
  hideImage: {
    type: Boolean,
    default: false,
  },
});

const LscEmptyStateDefaultImage = defineAsyncComponent(() => import('./LscEmptyStateDefaultImage.svg'));
const LscEmptyStateErrorImage = defineAsyncComponent(() => import('./LscEmptyStateErrorImage.svg'));

const emptyStateVariantStyleConfig = tv({
  base: 'flex items-center justify-center',
  slots: {
    wrapper: 'flex w-full flex-col items-center',
    image: 'mb-4 flex aspect-[3/2] h-auto w-full items-center justify-center [&>*]:h-full',
    title: 'text-balance text-center font-semibold',
    message: 'mt-1 text-pretty text-center text-body-1',
    actions: 'mt-4 flex w-full justify-center gap-2',
  },
  variants: {
    size: {
      sm: {
        wrapper: 'max-w-[--lsds-c-empty-state-size-sm]',
        image: 'mb-3 max-w-[--lsds-c-empty-state-image-size-sm]',
        title: 'text-body-1',
        message: 'text-body-2',
        actions: 'mt-3',
      },
      md: {
        wrapper: 'max-w-[--lsds-c-empty-state-size-md]',
        image: 'max-w-[--lsds-c-empty-state-image-size-md]',
        title: 'text-subtitle-2',
      },
      lg: {
        base: '',
        wrapper: 'max-w-[--lsds-c-empty-state-text-box-size-lg]',
        image: 'max-w-[--lsds-c-empty-state-image-size-lg]',
        title: 'max-w-[--lsds-c-empty-state-size-lg] text-subtitle-1',
        message: 'max-w-[--lsds-c-empty-state-size-lg]',
      },
    },
  },
});

const classes = computed(() =>
  emptyStateVariantStyleConfig({
    size: props.size,
  }),
);
</script>
<template>
  <div :class="classes.base()">
    <div :class="classes.wrapper()">
      <div :class="classes.image()">
        <!-- @slot Put the empty state image here -->
        <slot v-if="!hideImage" name="image">
          <LscSlotSwitch :name="variant" class="">
            <template #default>
              <LscEmptyStateDefaultImage />
            </template>
            <template #error>
              <LscEmptyStateErrorImage />
            </template>
          </LscSlotSwitch>
        </slot>
      </div>
      <h4 v-if="$slots.title || title" :class="classes.title()">
        <!-- @slot Title of the empty state. Only use this slot if you need to add HTML, otherwise just use the prop  -->
        <slot name="title">{{ title }}</slot>
      </h4>
      <p v-if="$slots.message || message" :class="classes.message()">
        <!-- @slot message of the empty state. Only use this if you need to add HTML, otherwise just use the prop -->
        <slot name="message">{{ message }}</slot>
      </p>
      <div v-if="$slots.actions" :class="classes.actions()">
        <!-- @slot Place call to action buttons in here -->
        <slot name="actions" />
      </div>
    </div>
  </div>
</template>
