<script setup>
import { useQuickViewRoute, useRoute } from './useRoute';

const route = useRoute();
const quickViewRoute = useQuickViewRoute();

const delayingMainView = shallowRef(false);

function hasQuickView() {
  return quickViewRoute.fullPath !== undefined;
}

// Delays rendering of the main view to prioritize rendering of the quick view.
function delayMainView() {
  delayingMainView.value = true;
  const timeout = setTimeout(resumeMainView, 1500);
  const unwatch = watch(hasQuickView, () => {
    if (!hasQuickView()) {
      resumeMainView();
    }
  });
  function resumeMainView() {
    unwatch();
    clearTimeout(timeout);
    delayingMainView.value = false;
  }
}

if (hasQuickView()) {
  // If the quick view is present,
  // we delay rendering of the main view to prioritize rendering of the quick view.
  delayMainView();
} else if (route.meta.isQuickViewRoute) {
  // If the quick view is missing but the main route should be rendered as a quick view,
  // then the appropriate route component will be rendered by `RouteMainView` instead of `RouteQuickView`.
  // The route component will then typically set `quickViewBackgroundPath` and trigger the router to refresh,
  // so that a quick view would be rendered with an appropriate background route. If that happens,
  // we delay rendering of the main view to prioritize rendering of the quick view.
  const initialFullPath = route.fullPath;
  const unwatch = watch(
    () => route.fullPath,
    () => {
      unwatch();
      if (route.fullPath !== initialFullPath && quickViewRoute.fullPath === initialFullPath) {
        delayMainView();
      }
    },
  );
}
</script>

<template>
  <WidgetLoadingProgress v-if="delayingMainView" />
  <RouterView v-else :route="route" />
</template>
