<script setup>
import { useProjectsV3Loader } from '@/api';
import { useI18n } from '@/util';
import ReportTableCustomReport from '../../table/ReportTableCustomReport.vue';
import { reportBuilderSampleData, reportBuilderSampleDataTotalRow } from '../constants.js';
import { useReportCustomBuilder } from '../useReportCustomBuilder.js';
import { useReportCustomBuilderFilters } from '../useReportCustomBuilderFilters.js';

const { t } = useI18n();
const { currentReport, reportColumns } = useReportCustomBuilder();
const { filterParams } = useReportCustomBuilderFilters();

const count = 10;
const pageSize = 10;
const state = useProjectsV3Loader({
  params: computed(() => ({
    include:
      'projectOwners,projectUpdates,companies,companies.countries,companies.industries,projectCategories,portfolioCards,portfolioBoards,portfolioColumns,tags,customfields,customfieldProjects,projectBudgets,activities.latest,users',
    projectCategoryIds: filterParams.value.categoryIds,
    projectCompanyIds: filterParams.value.companyIds,
    maxLastActivityDate: currentReport.value.endAt.toFormat('yyyy-MM-dd'),
    includeCompletedProjects: filterParams.value.includeCompletedProjects,
    includeArchivedProjects: filterParams.value.includeArchivedProjects,
    onlyStarredProjects: filterParams.value.onlyStarredProjects,
    projectIds: filterParams.value.projectIds,
    projectOwnerIds: filterParams.value.userIds,
    projectStatuses: filterParams.value.status,
    minLastActivityDate: currentReport.value.startAt.toFormat('yyyy-MM-dd'),
    projectTagIds: filterParams.value.tagIds,
  })),
  count,
  pageSize,
});

const { items: projects } = state;

const projectsWithSampleData = computed(() => {
  return projects.value?.map((project, index) => {
    const board = project.portfolioCards?.[0].column ?? null;

    const customfields = {};

    for (const customfield of project.customFieldValues ?? []) {
      if (customfield.customFieldId) {
        customfields[customfield.customFieldId] = {
          ...customfield.customField,
          value: customfield.value,
        };
      }
    }

    return {
      ...reportBuilderSampleData[index],
      ...project,
      ...customfields,
      board: board ? [board] : null,
      company: { name: project.company.name },
      dateCreated: project.createdAt,
      end: project.endDate,
      lastActive: project.updatedAt,
      project: project.name,
      projectId: project.id,
      start: project.startDate,
      update: project.update?.text,
    };
  });
});

const customfieldsMap = computed(() => {
  return projects.value?.reduce((acc, project) => {
    for (const customfield of project.customFieldValues ?? []) {
      if (customfield.customFieldId) {
        acc[customfield.customFieldId] = {
          ...customfield.customField,
          value: customfield.value,
        };
      }
    }

    return acc;
  }, {});
});

const reportTypeColumn = {
  id: 'projectName',
  name: t('Project'),
  enabled: true,
  draggable: false,
  width: 200,
  align: 'left',
};

const tableColumns = computed({
  get() {
    const customfields = customfieldsMap.value;
    return [
      reportTypeColumn,
      ...reportColumns.value.map((column) =>
        column.isCustomField
          ? {
              ...column,
              choices:
                customfields[column.id]?.options?.choices?.reduce((acc, choice) => {
                  acc[choice.value] = choice;
                  return acc;
                }, {}) ?? {},
            }
          : column,
      ),
    ];
  },
  set(_columns) {
    reportColumns.value = _columns.filter((column) => column.id !== 'projectName');
  },
});
</script>

<template>
  <WidgetLoadingState :state="state">
    <template #defaultOrLoading>
      <ReportTableCustomReport
        v-model:columns="tableColumns"
        :items="projectsWithSampleData"
        :summaryRowData="reportBuilderSampleDataTotalRow"
        :showTotalRow="currentReport.summary"
      />
    </template>
    <template #blank>
      <LscEmptyState
        class="h-full"
        size="lg"
        :title="t('There are no projects that match your criteria')"
        :message="t('Please adjust your date range or filter settings and try again')"
      />
    </template>
  </WidgetLoadingState>
</template>
