<script setup>
import { useWindowSize } from '@/util';
import { useLsDraggable } from '../../../composables/draggable/useLsDraggable';
import { LscDialogCardSizes } from './constants.js';
import LscDialogCardHeader from './LscDialogCardHeader.vue';

const props = defineProps({
  /**
   * The title to display in the dialog.
   */
  title: {
    type: String,
    default: '',
  },
  /**
   * An optional tooltip to display next to the title.
   */
  infoTooltip: {
    type: [String, Object],
    default: undefined,
  },
  /**
   * The prefix to use for data identifiers.
   */
  dataIdentifierPrefix: {
    type: String,
    default: undefined,
  },
  /**
   * The size of the dialog.
   * @default 'sm'
   * @type {PropType<typeof LscDialogCardSizes[number]>}
   */
  size: {
    type: String,
    default: 'sm',
    validator: (value) => LscDialogCardSizes.includes(value),
  },
});

const emit = defineEmits(['close']);

const { width } = useWindowSize();
const { draggable, resetPosition } = useLsDraggable();
const card = shallowRef(null);
const dragHandle = shallowRef();

const LscDialogCardVariantStyleConfig = tv({
  base: 'pointer-events-auto flex min-h-0 w-full min-w-0 flex-auto transform-gpu flex-col items-stretch overflow-hidden bg-default',
  slots: {
    container: 'flex h-full min-h-0 grow flex-col items-stretch',
    header: 'grow-0 p-6 pb-3',
    main: 'flex min-h-0 flex-1 flex-col items-stretch',
    content: 'overflow-y-auto px-6 py-3 text-body-1',
    actions: 'flex grow-0 items-center gap-2 p-6 pt-3',
  },
  variants: {
    size: {
      xs: 'max-w-[--lsds-c-dialog-size-xs]',
      sm: 'max-w-[--lsds-c-dialog-size-sm]',
      md: 'max-w-[--lsds-c-dialog-size-md]',
      lg: 'max-w-[--lsds-c-dialog-size-lg]',
      full: 'max-w-[--lsds-c-dialog-size-full]',
    },
  },
  compoundVariants: [
    {
      size: 'full',
      class: {
        base: 'rounded-none',
      },
    },
    {
      size: ['xs', 'sm', 'md', 'lg'],
      class: {
        base: 'rounded-md shadow-dialog',
      },
    },
  ],
});

const classes = computed(() =>
  LscDialogCardVariantStyleConfig({
    size: props.size,
  }),
);

function close() {
  emit('close');
}

watch(width, () => {
  if (card.value) {
    resetPosition(card);
  }
});

watch(
  [card, dragHandle],
  () => {
    if (card.value && dragHandle.value) {
      draggable(card, { dragHandle });
    }
  },
  { immediate: true },
);
</script>

<template>
  <div ref="card" :class="classes.base()">
    <slot name="container" :dragHandle="dragHandle">
      <div :class="classes.container()">
        <LscDialogCardHeader
          ref="dragHandle"
          :infoTooltip="infoTooltip"
          :dataIdentifierPrefix="dataIdentifierPrefix"
          :class="classes.header()"
          @close="close"
        >
          <template #prependHeaderLeft><slot name="prependHeaderLeft" /></template>
          <template #appendHeaderLeft><slot name="appendHeaderLeft" /></template>
          <template #title>
            <slot name="title">{{ title }}</slot>
          </template>
          <template #prependHeaderRight><slot name="prependHeaderRight" /></template>
          <template #appendHeaderRight><slot name="appendHeaderRight" /></template>
        </LscDialogCardHeader>

        <div :class="classes.main()">
          <slot name="content">
            <div :class="classes.content()" tabindex="0">
              <slot />
            </div>
          </slot>
        </div>

        <div v-if="$slots.actions" :class="classes.actions()"><slot name="actions" /></div>
      </div>
    </slot>
  </div>
</template>
