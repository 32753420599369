import { useFeatures } from '@/api';
import { useLocalStorage } from '@/util';

const symbol = Symbol('useDarkMode');

export function provideDarkMode() {
  const { darkModeEnabled } = useFeatures();
  const isDark = useLocalStorage('teamwork/useDarkMode/isDark', false);

  // Toggle the dark mode
  function toggleDark() {
    isDark.value = !isDark.value;
  }

  // Watch for changes in darkModeEnabled LD flag
  // and force light mode if it gets disabled
  watch(
    darkModeEnabled,
    (enabled) => {
      if (enabled === false) {
        // Forcefully remove the dark mode
        isDark.value = null;
      }
    },
    { immediate: true },
  );

  // Watch that will add dark mode class
  // to the html element
  watch(
    isDark,
    (enabled) => {
      document.documentElement.classList.toggle('dark', enabled);
    },
    { immediate: true },
  );

  provide(symbol, {
    toggleDark,
    isDark,
  });
}

export function useDarkMode() {
  return inject(symbol);
}
