<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t(
          "The sample data in this view shows that {userName} is over capacity in the next week. To solve this, you can click to expand the row showing David's work and re-assign a task to another user, or push it to a later date.",
          { userName: 'David UI Designer' },
        )
      }}
    </p>
  </div>
</template>
