import {
  useCohort,
  useCurrentUser,
  useExperimentA18,
  useExperimentA2420,
  useExperimentA41,
  usePreferences,
  useUserProductOutcomesV3Loader,
} from '@/api';
import { GOAL_TOUR_MAPPING, GOALS_WITH_TOURS } from './constants';
import { useOnboardingTourData } from './data/useOnboardingTourData';
import { useActiveProductTourId } from './useActiveProductTourId';

/** @type {InjectionKey<ReturnType<typeof OnboardingTour>>} */
const symbol = Symbol('useOnboardingTour');

const outcomesTours = [
  {
    outcomeId: 1,
    associatedTours: [1, 2, 6],
  },
  {
    outcomeId: 2,
    associatedTours: [1, 2, 11],
  },
  {
    outcomeId: 3,
    associatedTours: [1, 2, 3],
  },
  {
    outcomeId: 4,
    associatedTours: [1, 2, 4],
  },
  {
    outcomeId: 5,
    associatedTours: [1, 2, 10],
  },
  {
    outcomeId: 6,
    associatedTours: [1, 2, 5],
  },
];

const precheckedItems = [1, 2, 24];
const minNumberOfChecklistItems = 6;

function OnboardingTour() {
  const { onboardingTourData } = useOnboardingTourData();
  const { productToursCompleted, onboardingSelectedIntegrations, onboardingSelectedGoalId } = usePreferences();
  const user = useCurrentUser();
  const {
    isPaid,
    isPaymentOverdue,
    isClientUser,
    isCollaborator,
    isSiteOwner,
    isSiteAdmin,
    isCompanyRoleContributorGrp,
    isInOwnerCompany,
    isContact,
  } = useCohort();
  const { isExpA18Variation } = useExperimentA18();
  const { isExpA41Variation1 } = useExperimentA41();
  const { isExpA2420Variation } = useExperimentA2420();
  const state = useUserProductOutcomesV3Loader({ userId: computed(() => user.value?.id), count: Infinity });

  const activeProductTourId = useActiveProductTourId();

  // EXP A-23-29
  const workerBeeOnboardingTours = [
    { ...onboardingTourData.joinYourFirstProject },
    { ...onboardingTourData.stayOnTopOfYourWork },
    { ...onboardingTourData.collaborateWithYourTeam },
    { ...onboardingTourData.trackYourTime },
  ];

  // EXP A-23-45
  const icpOnboardingTours = [
    { ...onboardingTourData.createProjectTour },
    { ...onboardingTourData.createTasksTour },
    { ...onboardingTourData.trackingBillableTime },
    { ...onboardingTourData.managingTeamCapacityTour },
    { ...onboardingTourData.reportingProjectHealthUtilization },
    { ...onboardingTourData.managingProjectsTour },
    { ...onboardingTourData.billingClientsForWork },
    { ...onboardingTourData.workingWithClients },
  ];

  // EXP A-23-18
  const freeForeverOnboardingTours = computed(() => {
    const trackingBillableTimeTourId = 3;
    const billingClientsForWorkTourId = 5;
    return [
      onboardingTourData.createProjectTour,
      onboardingTourData.createTasksTour,
      onboardingTourData.stayOnTopOfYourWork,
      onboardingTourData.collaborateWithYourTeam,
      state.items.value.some((item) => item.id === trackingBillableTimeTourId) && onboardingTourData.trackYourTime,
      state.items.value.some((item) => item.id === billingClientsForWorkTourId) &&
        onboardingTourData.billingClientsForWork2,
    ].filter(Boolean);
  });

  const productOutcomeTours = computed(() => {
    if (isExpA2420Variation.value && GOALS_WITH_TOURS.includes(parseInt(onboardingSelectedGoalId.value, 10))) {
      return Object.values(onboardingTourData).filter((x) =>
        GOAL_TOUR_MAPPING[onboardingSelectedGoalId.value]?.tours.includes(x.id),
      );
    }
    const filterByOutcomeId = outcomesTours.filter((x) => state.items.value.some((t) => t.id === x.outcomeId));
    return Object.values(onboardingTourData).filter((x) =>
      filterByOutcomeId.some((t) => t.associatedTours.includes(x.id)),
    );
  });

  const defaultTours = computed(() => [
    onboardingTourData.createProjectTour,
    onboardingTourData.createTasksTour,
    onboardingTourData.managingProjectsTour,
  ]);

  const allToursWithDefaults = computed(() => {
    const allChecklistProductTours = [...productOutcomeTours.value];
    if (allChecklistProductTours.length <= 0) {
      allChecklistProductTours.push(...defaultTours.value);
    }
    if (allChecklistProductTours.length < minNumberOfChecklistItems) {
      allChecklistProductTours.push(onboardingTourData.inviteTeamTour);
    }

    // experiment A-23-41
    if (isExpA41Variation1.value && onboardingSelectedIntegrations.value.includes('slack')) {
      allChecklistProductTours.push(onboardingTourData.connectWithSlack);
    }

    return allChecklistProductTours;
  });

  const checklistItems = computed(() => {
    function markItemsCompleted(item) {
      return {
        ...item,
        completed: productToursCompleted.value?.includes(item.id) || precheckedItems.includes(item.id),
      };
    }

    if (isExpA18Variation.value) {
      return freeForeverOnboardingTours.value.map(markItemsCompleted);
    }

    if (
      isCompanyRoleContributorGrp.value &&
      !isSiteOwner.value &&
      isInOwnerCompany.value &&
      !isContact.value &&
      !isCollaborator.value
    ) {
      return workerBeeOnboardingTours.map(markItemsCompleted);
    }

    if ((isPaid.value || isPaymentOverdue.value) && !isClientUser.value && !isCollaborator.value) {
      if (isSiteAdmin.value) {
        return icpOnboardingTours.map(markItemsCompleted);
      }

      return workerBeeOnboardingTours.map(markItemsCompleted);
    }

    return allToursWithDefaults.value.map(markItemsCompleted);
  });

  const completedItems = computed(() => {
    return checklistItems.value.filter((x) => x.completed);
  });

  const incompleteItems = computed(() => {
    return checklistItems.value.filter((x) => !x.completed);
  });

  const isChecklistComplete = computed(() => {
    return incompleteItems.value.length <= 0;
  });

  const taskProgress = computed(() => {
    const completed = completedItems.value.length;
    return Math.round((completed / checklistItems.value.length) * 100);
  });

  function updateTourPreferences(productTourId) {
    productToursCompleted.value = [...productToursCompleted.value, productTourId];
  }

  return {
    checklistItems,
    completedItems,
    incompleteItems,
    isChecklistComplete,
    taskProgress,
    state,
    updateTourPreferences,
    activeProductTourId,
  };
}

export function provideOnboardingTour() {
  const onboardingTour = OnboardingTour();
  provide(symbol, onboardingTour);
  return onboardingTour;
}

export function useOnboardingTour() {
  return inject(symbol);
}
