<script setup>
import { useI18n } from '@/util';

const { t } = useI18n();
</script>
<template>
  <div class="text-body-1">
    <p>
      {{
        t('Take 2 mins to see the value that {productName} can add to your resourcing with a real world example.', {
          productName: 'Teamwork',
        })
      }}
    </p>
  </div>
</template>
