<script setup>
import { useI18n } from '@/util';
import { useReportCustomBuilder } from '../useReportCustomBuilder.js';
import ReportCustomBuilderPreviewMilestoneTable from './ReportCustomBuilderPreviewMilestoneTable.vue';
import ReportCustomBuilderPreviewProjectTable from './ReportCustomBuilderPreviewProjectTable.vue';
import ReportCustomBuilderPreviewTaskTable from './ReportCustomBuilderPreviewTaskTable.vue';
import ReportCustomBuilderPreviewUserTable from './ReportCustomBuilderPreviewUserTable.vue';

const { t } = useI18n();
const { currentReport } = useReportCustomBuilder();

const defaultReportNames = {
  project: t('Project report'),
  milestone: t('Milestone report'),
  task: t('Task report'),
  user: t('User report'),
};

const reportName = computed(() => {
  const report = currentReport.value;

  if (report.name) {
    return report.name;
  }

  return defaultReportNames[report.type];
});
</script>

<template>
  <div class="flex h-full w-full flex-1 flex-col gap-4 overflow-hidden p-5">
    <div class="flex items-center gap-2">
      <h5 class="text-body-1 font-semibold">
        {{ t('Preview') }}
      </h5>
      <span class="text-body-2 text-subtle">
        {{ t('All numerical cells in this preview report contain sample data.') }}
      </span>
    </div>
    <div class="flex flex-1 flex-col overflow-auto rounded-md bg-default p-3 shadow-2">
      <LscOverflowEllipsis class="mb-3 shrink-0 text-h4 font-semibold">
        {{ reportName }}
      </LscOverflowEllipsis>
      <ReportCustomBuilderPreviewMilestoneTable v-if="currentReport.type === 'milestone'" />
      <ReportCustomBuilderPreviewProjectTable v-else-if="currentReport.type === 'project'" />
      <ReportCustomBuilderPreviewTaskTable v-else-if="currentReport.type === 'task'" />
      <ReportCustomBuilderPreviewUserTable v-else-if="currentReport.type === 'user'" />
    </div>
  </div>
</template>
