import { useLocalStorage } from '@/util';

export function useLegacySwitches() {
  return {
    // We keep `useLightspeedView` in the key name for now for backward compatibility.
    listView: useLocalStorage('teamwork/useLightspeedView/newListView', true),
    boardView: useLocalStorage('teamwork/useLightspeedView/boardView', true),
    people: useLocalStorage('teamwork/useLightspeedView/people', true),
    customReports: useLocalStorage('teamwork/useLightspeedView/customReports', true),
    plannedVsActualTasksReportPage: useLocalStorage('teamwork/useLightspeedView/plannedVsActualTasksReportPage', true),
    plannedVsActualMilestonesReportPage: useLocalStorage(
      'teamwork/useLightspeedView/plannedVsActualMilestonesReportPage',
      true,
    ),
    teams: useLocalStorage('teamwork/useLightspeedView/teams', true),
    reportGallery: useLocalStorage('teamwork/useLightspeedView/reportGallery', true),
    statuses: useLocalStorage('teamwork/useLightspeedView/statuses', true),
    scheduler: useLocalStorage('teamwork/useLightspeedView/scheduler', true),
    reportsUtilization: useLocalStorage('teamwork/useLightspeedView/reportsUtilization', true),
    planningOverview: useLocalStorage('teamwork/useLightspeedView/planningOverview', true),
    projectsTemplates: useLocalStorage('teamwork/useLightspeedView/projectsTemplates', true),
    projectLinks: useLocalStorage('teamwork/useLightspeedView/projectLinksPage', false),
  };
}
